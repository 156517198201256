::selection {
    background-color:#0575E6;
    color: #fff;
}
::-moz-selection {
    background-color:#0575E6;
    color: #fff;
}



.tab-style-1 .nav .nav-item a,
.more-btn:hover,
.more-btn:focus,
.more-btn:active,
.theme-color {
    color:#0575E6;
}


.theme-after-bg:after,
.btn-theme,
.theme-bg {
    background:#0575E6;
}


.feature-box-01:after,
.feature-box:hover,
.btn-theme {
    border-color:#0575E6;
}

.tab-style-1 .nav .nav-item a.active,
.m-btn-theme,
.title-section h2:after,
.m-btn-white:hover,
.theme-after:after,
.theme-g-bg{
    background: #0575E6;
}


/*Reverce*/
.m-btn-theme:hover{
    background: #0575E6;
}

.m-btn-theme:hover,
.m-btn-white:hover {
    box-shadow: 0px 15px 20px rgba(5, 117, 230, 0.4);
}

@media (max-width: 991px){
    .header	{
        background:#0575E6;
    }
}


/*----------------------------------
Table of contents

01. Common
02. Buttons
03. Title
04. Header
05. Animation Effect
06. Home Banner
07. Feature
08. Overview section
09. Testimonial
10. Subscribe
11. Our Clients
12. Custom tabs
13. Price table
14. blog
15. Footer
16. Margins
17. Padding
----------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');


.loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99999;
    margin: 0;
    background: transparent;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-wrapper .center {
    width: 90px;
    height: 90px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.loader-wrapper .center .d {
    width: 8px;
    animation: dot 1200ms cubic-bezier(0.91, 1.42, 0.87, 0.99);
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    height: 8px;
    border-radius: 50%;
    background-color: #333;
    margin: 1px;
}

.loader-wrapper .center .d.d1 {
    animation-delay: 150ms;
}

.loader-wrapper .center .d.d2 {
    animation-delay: 300ms;
}

.loader-wrapper .center .d.d3 {
    animation-delay: 450ms;
}

.loader-wrapper .center .d.d4 {
    animation-delay: 600ms;
}
.loader-wrapper .center .d.d5 {
    animation-delay: 750ms;
}

@keyframes dot {
    0% {
        transform: translateY(-0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-10px);
    }
}



/*---------------------------------
* 01. Common
-----------------------------------*/
body {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 1.86;
    color: #3c3c3c;
    overflow-x: hidden;
}

.font-alt {
    font-family: 'Quicksand', sans-serif;
}

img {
    max-width: 100%;
}

.full-screen {
    min-height: 100vh;
}

.section {
    padding: 100px 0;
}

.grey-bg{
    background-color: #F8F9FC;
}
.border-top-grey {
    border-top: 1px solid #ddd;
}

.img-shadow {
    box-shadow: 0 20px 30px rgba(125,147,178,.3);
}

@media (max-width: 991px){
    .section {
        padding: 80px 0;
    }
}

@media (max-width: 767px){
    .section {
        padding: 60px 0;
    }
}


/*---------------------------------
* 02. Buttons
-----------------------------------*/
.m-btn-white {
    height: 45px;
    line-height: 45px;
    padding: 0 30px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    display: inline-block;
    text-decoration: none !important;
}

.m-btn-white:hover {
    color: #fff;
}

.m-btn-theme {
    height: 45px;
    line-height: 45px;
    padding: 0 30px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    display: inline-block;
    text-decoration: none !important;
}

.m-btn-theme:hover {
    color: #fff;
    -webkit-transform: translateY(-7px);
    -moz-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px);
}



.more-btn {
    color: #000;
    font-weight: 500;
    position: relative;
    padding: 3px;
    display: inline-block;
    line-height: 18px;
    font-size: 16px;
    text-decoration: none !important;
    -webkit-transition: ease all 0.4s;
    -moz-transition: ease all 0.4s;
    -o-transition: ease all 0.4s;
    transition: ease all 0.4s;
}
.more-btn i {
    position: absolute;
    right: 0;
    top: 5px;
    opacity: 0;
    -webkit-transition: ease all 0.4s;
    -moz-transition: ease all 0.4s;
    -o-transition: ease all 0.4s;
    transition: ease all 0.4s;
}

.more-btn:hover i {
    right: -20px;
    opacity: 1;
}

/*---------------------------------
* 03. Title
-----------------------------------*/

.title-section h2  {
    margin: 0 0 20px;
    padding: 0 0 20px;
    color: #000;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    text-transform: capitalize;
}
.title-section h2:after {
    width: 50px;
    height: 2px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin:auto;
    background: #000;
}

.title-section h2.left:after {
    right: auto;
}

.title-section p {
    margin: 0;
    font-size: 15px;
}

/*---------------------------------
* 04. Header
-----------------------------------*/
.header-01 .navbar-toggler {
    outline: none;
}
.header-01 .navbar-toggler span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
}
.header-01 .navbar-toggler span + span {
    margin-top: 4px;
}

.header-01 ,
.header-01 * {
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -o-transition: ease all 0.3s;
    transition: ease all 0.3s;
}
.header-01 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 8;
    padding: 10px 0;
}
.header-01 .navbar-brand {
    font-weight: 600;
    color: #fff;
}
.header-01 .navbar-brand img {
    max-height: 70px;
}
.header-01 .navbar {
    padding: 0;
}
.header-01 .navbar-nav li:not(:last-child) {
    margin-right: 20px;
}
.header-01 .navbar-nav li {
    position: relative;
}
.header-01 .navbar-nav li > ul {
    position: absolute;
    background: #fff;
    top: 100%;
    left: 0;
    width: 180px;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    padding: 10px;
}
.header-01 .navbar-nav li:hover > ul {
    opacity: 1;
    visibility: visible;
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -o-transition: ease all 0.3s;
    transition: ease all 0.3s;

}
.header-01 .navbar-nav li > ul > li {
    margin: 0 !important;
    padding: 4px 0;
    list-style: none;
}
.header-01 .navbar-nav li > ul > li > a {
    color: #333;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 600;
    display: block;
}
.header-01 .navbar-nav li > ul > li > a:hover {
    color: #111;
}
.header-01 .navbar-nav .nav-link {
    float: left;
    padding: 11px 0;
    color: #333;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 2px;
    position: relative;
}
.header-01 .navbar-nav .nav-link:after {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: auto;
    height: 1px;
    width: 0;
    background: #333;
    transition: ease all 0.3s;
}
.header-01 .navbar-nav .nav-link.active:after {
    width: 75%;
}

.header-01 .navbar-nav .nav-link-btn {

}
.fixed-header.header-01 {
    background: #fff;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px){
    .header-01 .navbar-nav li > ul {
        display: none;
    }
    .header-01 {
        padding: 18px 0;
    }
    .header-01 .navbar-nav .nav-link {
        padding: 12px 0;
    }
    .header-01 .navbar-nav .nav-link.active:after {
        left: 0;
        right: 0;
        bottom: 5px;
        height: 2px;
    }
    .header-01 .navbar-nav .nav-link-btn {
        margin-left: 0px;
        margin-top: 10px;
    }
}

/*---------------------------------
* 05. Animation Effect
-----------------------------------*/


/*---------------------------------
* 06. Home Banner
-----------------------------------*/
.home-banner {
}



/*---------------------------------
* 07. Feature
-----------------------------------*/
.feature-box {
    padding: 90px 40px;
    background: #fff;
    transition: ease all 0.3s;
    border-top: 5px solid #ddd;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}
.feature-box .f-icon {
    padding-bottom: 25px;
}
.feature-box .f-icon i {
    font-size: 50px;
}
.feature-box h4 {
    margin: 0;
    padding: 0 0 20px;
    font-size: 18px;
    color: #000;
    font-weight:600;
}
.feature-box:hover {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
}


.feature-box-01 {
    z-index: 1;
    padding: 30px;
    background: #fff;
    border: 1px solid #ddd;
    -webkit-transition: ease all 0.4s;
    -moz-transition: ease all 0.4s;
    -o-transition: ease all 0.4s;
    transition: ease all 0.4s;
}
.feature-box-01:hover {
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}

.feature-box-01 .f-icon {
    margin-bottom: 15px;
}
.feature-box-01 .f-icon i {
    font-size: 35px;
}

.feature-box-01 h4 {
    margin: 0;
    padding: 0 0 20px;
    font-size: 18px;
    color: #333;
    font-weight: 600;
    letter-spacing: 1px;
}
.feature-box-01 .more-btn {
    text-transform: uppercase;
    font-size: 12px;
}


@media (max-width: 991px){
    .feature-box {
        padding: 30px 20px;
    }
    .feature-box-01 {
        padding: 15px;
    }
}

/*---------------------------------
* 08. Overview section
-----------------------------------*/
.overview-section {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.std-box h2 {
    position: relative;
    margin: 0;
    padding: 0 0 20px;
    font-weight: 600;
}
.std-box h2:after {
    content: "";
    width: 40px;
    height: 2px;
    background: #38385f;
    position: absolute;
    bottom: 10px;
    left: 0;
}

.std-box p {
    font-size: 15px;
}

.std-box .list-style {
    margin-top: 25px;
}

.overview-section-large .container {
    width: 1540px;
    max-width: 100%;
}
.overview-section-large .std-box {
    padding: 10%;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    position: relative;
}

/*---------------------------------
* 09. Testimonial
-----------------------------------*/
.owl-dots {
    text-align: center;
    margin-top: 20px;
    line-height: normal;
}
.owl-dots .owl-dot {
    border: 1px solid #636363;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 6px;
    vertical-align: top;
}
.owl-dots .owl-dot.active {
    border: transparent;
}

.testimonial-col {
    padding: 12%;
    background: #fff;
    margin: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 2px 2px 18px -3px rgba(0, 0, 0, 0.28);
}
.testimonial-col:after {
    width: 100px;
    height: 80px;
    content: "";
    position: absolute;
    top: -13px;
    left: -20px;
    float: left;
    border-radius: 90px;
    -webkit-transform: skew(39deg, -38deg);
    -moz-transform: skew(39deg, -38deg);
    -ms-transform: skew(39deg, -38deg);
    transform: skew(39deg, -38deg);

}
.testimonial-col .avtar-box {
    width: 100%;
    display: inline-block;
    position: relative;
    padding-left: 75px;
    min-height: 60px;
    z-index: 1;
}
.testimonial-col .avtar-box .avtar {
    position: absolute;
    top: 0;
    left: 0;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #fff;
}
.testimonial-col .avtar-box h6 {
    margin: 0;
    padding: 10px 0 0;
}
.testimonial-col .avtar-box h6 span {
    font-weight: 500;
    font-size: 14px;
    color: #999;
}
.testimonial-col .avtar-box h6 label {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #222;
}
.testimonial-col .speac {
    padding-top: 20px;
    position: relative;
    z-index: 1;
}
.testimonial-col .speac p {
    margin: 0;
}


/*---------------------------------
* 10. Subscribe
-----------------------------------*/
.subscribe-section {
    position: relative;
}
.subscribe-form .sf-form {
    position: relative;
}
.subscribe-form .input-control {
    height: 60px;
    padding: 10px 30px;
    border-radius: 30px;
    color: #111;
    border: 1px solid #ddd;
    width: 95%;
    vertical-align: top;
    outline: none;
    font-weight: 600;
    font-size: 14px;
}

.subscribe-form button {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    font-size: 15px;
    box-shadow: none !important;
    -webkit-transform: translateY(0px) !important;
    -moz-transform: translateY(0px) !important;
    -o-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
}

@media (max-width: 767px){
    .subscribe-form .input-control {
        width: 100%;
        margin-bottom: 10px;
    }
    .subscribe-form button {
        position: relative;
    }
}

/*---------------------------------
* 11. Our Clients
-----------------------------------*/
.clients-list {
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.clients-list ul {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.clients-list li {
    float: left;
    padding: 20px;
    position: relative;
}
.clients-list li img {
    display: block;
    margin: 0 auto;
}
.clients-border li:before,
.clients-border li:after {
    content: '';
    position: absolute;
}
.clients-border li:before {
    height: 100%;
    top: 0;
    left: -1px;
    border-left: 1px solid rgba(0,0,0,0.1);
}
.clients-border li:after {
    width: 100%;
    height: 0;
    top: auto;
    left: 0;
    bottom: -1px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.clients-col-5 li {
    width: 20%;
}

.clients-col-4 li {
    width: 25%;
}

.clients-col-3 li {
    width: 33.33%;
}
.clients-col-2 li {
    width: 50%;
}

@media (max-width: 767px){
    .clients-col-5 li,
    .clients-col-4 li,
    .clients-col-3 li,
    .clients-col-2 li {
        width: 50%;
    }
}


/*---------------------------------
* 12. Custom tabs
-----------------------------------*/
.tab-style-1 .nav {
    border-radius: 5px;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    background: #fff;
}
.tab-style-1 .nav .nav-item a {
    padding: 15px 10px;
    display: block;
    border-radius: 5px;
    text-decoration: none;
    font-size: 20px;
}

.tab-style-1 .nav .nav-item a.active {
    color: #fff;
}


/*---------------------------------
* 13. Price table
-----------------------------------*/
.price-table {
    background: #fff;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.price-table .price-table-head {
    padding:40px 12%;
    position: relative;
    z-index: 1;
    color: #fff;
}
.price-table .price-table-head:after {
    content: "";
    position: absolute;
    top: -150px;
    width: 400px;
    height: 350px;
    border-radius: 107px;
    left: -60px;
    z-index: -1;
    box-shadow: 15px 4px 0px 0px #ddd;
    -webkit-transform: skew(-54deg, 13deg);
    -moz-transform: skew(-54deg, 13deg);
    -o-transform: skew(-54deg, 13deg);
    transform: skew(-54deg, 13deg);

}
.price-table .price-table-head h2 {
    font-size:30px;
    font-weight: 300;
    margin: 0 0 25px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.price-table .price-table-head .pricing {
    font-size: 80px;
    line-height: 60px;
    font-weight: 300;
}
.price-table .price-table-head .pricing span {
    display: inline-block;
    vertical-align: baseline;
    font-size: 15px;
    line-height: normal;
}

.price-table .price-table-body {
    padding:40px 12%;
}
.price-table .price-table-body ul {
    margin: 0;
    padding: 0 0 30px;
    list-style: none;
}
.price-table .price-table-body li {
    font-size: 17px;
    font-weight: 600;
    color: #000;
    margin: 10px 0;
}

/*---------------------------------
* 14. blog
-----------------------------------*/
.blog-item {
    box-shadow: 2px 2px 18px -3px rgba(0, 0, 0, 0.28);
}

.blog-item .blog-contents {
    padding: 15px;
    background: #fff;
}
.blog-item .blog-contents h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 10px;
    padding: 0;
}
.blog-item .blog-contents h3 a {
    text-decoration: none !important;
    color: #333;
}
.blog-item .blog-contents .blog-meta {
    margin-bottom: 15px;
}
.blog-item .blog-contents .blog-meta p{
    font-size: 12px;
    font-weight: 600;
    opacity: 0.8;
    margin: 0;
}
.blog-item .blog-action a {
    font-size: 13px;
    text-transform: uppercase;
    color: #000;
}

/*---------------------------------
* 15. Footer
-----------------------------------*/
.footer-home {
    position: relative;
    background: #222;
}
.footer-home .container {
    position: relative;
    z-index: 1;
}
.footer-home:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    -o-background-size: 100% auto;
    background-size: 100% auto;

}

.footer-home-section {
    padding-top: 80px;
}

.footer-home-section h4 {
    color: #fff;
    font-size: 18px;
    margin: 0 0 30px;
    letter-spacing: 1px;
    font-weight: 600;
}
.footer-home-section .footer-home-text {
    color: #fff;
}

.footer-home-section .social-icons {
    margin: 20px 0 0 0;
    padding: 0;
}
.footer-home-section .social-icons li {
    display: inline-block;
    vertical-align: top;
}
.footer-home-section .social-icons li a {
    display: block;
    padding: 0;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: transparent;
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -o-transition: ease all 0.3s;
    transition: ease all 0.3s;
}
.footer-home-section .social-icons li a:hover {
    background: rgba(0,0,0,0.5);
}

.footer-home-section .fot-link {
    margin: 0;
    padding: 0;
}
.footer-home-section .fot-link li {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}
.footer-home-section .fot-link a {
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.066em;
}
.footer-home-section p {
    margin: 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.066em;
}
.footer-home-copy {
    margin-top: 60px;
    border-top: 1px solid rgba(255,255,255,.14);
    padding: 25px 0;
    text-align: center;
}
.footer-home-copy p {
    margin: 0;
}


/*==============================
  16. Margins
================================*/

/*Margin All*/
.m-auto-all { margin: auto;}
.m-0px { margin: 0;}
.m-5px {margin:5px}
.m-10px {margin:10px}
.m-15px {margin:15px}
.m-20px {margin:20px}
.m-25px {margin:25px}
.m-30px {margin:30px}
.m-35px {margin:35px}
.m-40px {margin:40px}
.m-45px {margin:45px}
.m-50px {margin:50px}
.m-55px {margin:55px}
.m-60px {margin:60px}
.m-65px {margin:65px}
.m-70px {margin:70px}
.m-75px {margin:75px}
.m-80px {margin:80px}
.m-85px {margin:85px}
.m-90px {margin:90px}
.m-95px {margin:95px}
.m-100px {margin:100px}

/*Margin top buttom*/
.m-0px-tb  {margin-top:0px;  margin-bottom:0px;}
.m-5px-tb  {margin-top:5px;  margin-bottom:5px;}
.m-10px-tb {margin-top:10px; margin-bottom:10px;}
.m-15px-tb {margin-top:15px; margin-bottom:15px;}
.m-20px-tb {margin-top:20px; margin-bottom:20px;}
.m-25px-tb {margin-top:25px; margin-bottom:25px;}
.m-30px-tb {margin-top:30px; margin-bottom:30px;}
.m-35px-tb {margin-top:35px; margin-bottom:35px;}
.m-40px-tb {margin-top:40px; margin-bottom:40px;}
.m-45px-tb {margin-top:45px; margin-bottom:45px;}
.m-50px-tb {margin-top:50px; margin-bottom:50px;}
.m-55px-tb {margin-top:55px; margin-bottom:55px;}
.m-60px-tb {margin-top:60px; margin-bottom:60px;}
.m-65px-tb {margin-top:65px; margin-bottom:65px;}
.m-70px-tb {margin-top:70px; margin-bottom:70px;}
.m-75px-tb {margin-top:75px; margin-bottom:75px;}
.m-80px-tb {margin-top:80px; margin-bottom:80px;}
.m-85px-tb {margin-top:85px; margin-bottom:85px;}
.m-90px-tb {margin-top:90px; margin-bottom:90px;}
.m-95px-tb {margin-top:95px; margin-bottom:95px;}
.m-100px-tb {margin-top:100px; margin-bottom: 100px;}

/*Margin left right*/
.m-0px-lr  {margin-left:0px; margin-right:0px}
.m-5px-lr  {margin-left:5px; margin-right:5px}
.m-10px-lr {margin-left:10px; margin-right:10px}
.m-15px-lr {margin-left:15px; margin-right:15px}
.m-20px-lr {margin-left:20px; margin-right:20px}
.m-25px-lr {margin-left:25px; margin-right:25px}
.m-30px-lr {margin-left:30px; margin-right:30px}
.m-35px-lr {margin-left:35px; margin-right:35px}
.m-40px-lr {margin-left:40px; margin-right:40px}
.m-45px-lr {margin-left:45px; margin-right:45px}
.m-50px-lr {margin-left:50px; margin-right:50px}
.m-55px-lr {margin-left:55px; margin-right:55px}
.m-60px-lr {margin-left:60px; margin-right:60px}
.m-65px-lr {margin-left:65px; margin-right:65px}
.m-70px-lr {margin-left:70px; margin-right:70px}
.m-75px-lr {margin-left:75px; margin-right:75px}
.m-80px-lr {margin-left:80px; margin-right:80px}
.m-85px-lr {margin-left:85px; margin-right:85px}
.m-90px-lr {margin-left:90px; margin-right:90px}
.m-95px-lr {margin-left:95px; margin-right:95px}
.m-100px-lr {margin-left:100px; margin-right:100px}


/*Margin top*/
.m-0px-t  {margin-top:0px}
.m-5px-t  {margin-top:5px}
.m-10px-t {margin-top:10px}
.m-15px-t {margin-top:15px}
.m-20px-t {margin-top:20px}
.m-25px-t {margin-top:25px}
.m-30px-t {margin-top:30px}
.m-35px-t {margin-top:35px}
.m-40px-t {margin-top:40px}
.m-45px-t {margin-top:45px}
.m-50px-t {margin-top:50px}
.m-55px-t {margin-top:55px}
.m-60px-t {margin-top:60px}
.m-65px-t {margin-top:65px}
.m-70px-t {margin-top:70px}
.m-75px-t {margin-top:75px}
.m-80px-t {margin-top:80px}
.m-85px-t {margin-top:85px}
.m-90px-t {margin-top:90px}
.m-95px-t {margin-top:95px}
.m-100px-t {margin-top:100px}

.m-100px-t-m {margin-top:-100px}

/*Margin Bottom*/
.m-0px-b {margin-bottom:0px}
.m-5px-b {margin-bottom:5px}
.m-10px-b {margin-bottom:10px}
.m-15px-b {margin-bottom:15px}
.m-20px-b {margin-bottom:20px}
.m-25px-b {margin-bottom:25px}
.m-30px-b {margin-bottom:30px}
.m-35px-b {margin-bottom:35px}
.m-40px-b {margin-bottom:40px}
.m-45px-b {margin-bottom:45px}
.m-50px-b {margin-bottom:50px}
.m-55px-b {margin-bottom:55px}
.m-60px-b {margin-bottom:60px}
.m-65px-b {margin-bottom:65px}
.m-70px-b {margin-bottom:70px}
.m-75px-b {margin-bottom:75px}
.m-80px-b {margin-bottom:80px}
.m-85px-b {margin-bottom:85px}
.m-90px-b {margin-bottom:90px}
.m-95px-b {margin-bottom:95px}
.m-100px-b {margin-bottom:100px}

/*Margin Left*/
.m-0px-l  {margin-left:0px}
.m-5px-l  {margin-left:5px}
.m-10px-l {margin-left:10px}
.m-15px-l {margin-left:15px}
.m-20px-l {margin-left:20px}
.m-25px-l {margin-left:25px}
.m-30px-l {margin-left:30px}
.m-35px-l {margin-left:35px}
.m-40px-l {margin-left:40px}
.m-45px-l {margin-left:45px}
.m-50px-l {margin-left:50px}
.m-55px-l {margin-left:55px}
.m-60px-l {margin-left:60px}
.m-65px-l {margin-left:65px}
.m-70px-l {margin-left:70px}
.m-75px-l {margin-left:75px}
.m-80px-l {margin-left:80px}
.m-85px-l {margin-left:85px}
.m-90px-l {margin-left:90px}
.m-95px-l {margin-left:95px}
.m-100px-l {margin-left:100px}

/*Margin right*/
.m-0px-r  {margin-right:0px}
.m-5px-r  {margin-right:5px}
.m-10px-r {margin-right:10px}
.m-15px-r {margin-right:15px}
.m-20px-r {margin-right:20px}
.m-25px-r {margin-right:25px}
.m-30px-r {margin-right:30px}
.m-35px-r {margin-right:35px}
.m-40px-r {margin-right:40px}
.m-45px-r {margin-right:45px}
.m-50px-r {margin-right:50px}
.m-55px-r {margin-right:55px}
.m-60px-r {margin-right:60px}
.m-65px-r {margin-right:65px}
.m-70px-r {margin-right:70px}
.m-75px-r {margin-right:75px}
.m-80px-r {margin-right:80px}
.m-85px-r {margin-right:85px}
.m-90px-r {margin-right:90px}
.m-95px-r {margin-right:95px}
.m-100px-r {margin-right:100px}


@media (max-width: 1200px){
    /*Margin All*/
    .lg-m-0px { margin: 0;}
    .lg-m-5px {margin:5px}
    .lg-m-10px {margin:10px}
    .lg-m-15px {margin:15px}
    .lg-m-20px {margin:20px}
    .lg-m-25px {margin:25px}
    .lg-m-30px {margin:30px}
    .lg-m-35px {margin:35px}
    .lg-m-40px {margin:40px}
    .lg-m-45px {margin:45px}
    .lg-m-50px {margin:50px}
    .lg-m-55px {margin:55px}
    .lg-m-60px {margin:60px}
    .lg-m-65px {margin:65px}
    .lg-m-70px {margin:70px}
    .lg-m-75px {margin:75px}
    .lg-m-80px {margin:80px}
    .lg-m-85px {margin:85px}
    .lg-m-90px {margin:90px}
    .lg-m-95px {margin:95px}
    .lg-m-100px {margin:100px}

    /*Margin top buttom*/
    .lg-m-0px-tb  {margin-top:0px;  margin-bottom:0px;}
    .lg-m-5px-tb  {margin-top:5px;  margin-bottom:5px;}
    .lg-m-10px-tb {margin-top:10px; margin-bottom:10px;}
    .lg-m-15px-tb {margin-top:15px; margin-bottom:15px;}
    .lg-m-20px-tb {margin-top:20px; margin-bottom:20px;}
    .lg-m-25px-tb {margin-top:25px; margin-bottom:25px;}
    .lg-m-30px-tb {margin-top:30px; margin-bottom:30px;}
    .lg-m-35px-tb {margin-top:35px; margin-bottom:35px;}
    .lg-m-40px-tb {margin-top:40px; margin-bottom:40px;}
    .lg-m-45px-tb {margin-top:45px; margin-bottom:45px;}
    .lg-m-50px-tb {margin-top:50px; margin-bottom:50px;}
    .lg-m-55px-tb {margin-top:55px; margin-bottom:55px;}
    .lg-m-60px-tb {margin-top:60px; margin-bottom:60px;}
    .lg-m-65px-tb {margin-top:65px; margin-bottom:65px;}
    .lg-m-70px-tb {margin-top:70px; margin-bottom:70px;}
    .lg-m-75px-tb {margin-top:75px; margin-bottom:75px;}
    .lg-m-80px-tb {margin-top:80px; margin-bottom:80px;}
    .lg-m-85px-tb {margin-top:85px; margin-bottom:85px;}
    .lg-m-90px-tb {margin-top:90px; margin-bottom:90px;}
    .lg-m-95px-tb {margin-top:95px; margin-bottom:95px;}
    .lg-m-100px-tb {margin-top:100px; margin-bottom: 100px;}

    /*Margin left right*/
    .lg-m-0px-lr  {margin-left:0px; margin-right:0px}
    .lg-m-5px-lr  {margin-left:5px; margin-right:5px}
    .lg-m-10px-lr {margin-left:10px; margin-right:10px}
    .lg-m-15px-lr {margin-left:15px; margin-right:15px}
    .lg-m-20px-lr {margin-left:20px; margin-right:20px}
    .lg-m-25px-lr {margin-left:25px; margin-right:25px}
    .lg-m-30px-lr {margin-left:30px; margin-right:30px}
    .lg-m-35px-lr {margin-left:35px; margin-right:35px}
    .lg-m-40px-lr {margin-left:40px; margin-right:40px}
    .lg-m-45px-lr {margin-left:45px; margin-right:45px}
    .lg-m-50px-lr {margin-left:50px; margin-right:50px}
    .lg-m-55px-lr {margin-left:55px; margin-right:55px}
    .lg-m-60px-lr {margin-left:60px; margin-right:60px}
    .lg-m-65px-lr {margin-left:65px; margin-right:65px}
    .lg-m-70px-lr {margin-left:70px; margin-right:70px}
    .lg-m-75px-lr {margin-left:75px; margin-right:75px}
    .lg-m-80px-lr {margin-left:80px; margin-right:80px}
    .lg-m-85px-lr {margin-left:85px; margin-right:85px}
    .lg-m-90px-lr {margin-left:90px; margin-right:90px}
    .lg-m-95px-lr {margin-left:95px; margin-right:95px}
    .lg-m-100px-lr {margin-left:100px; margin-right:100px}


    /*Margin top*/
    .lg-m-0px-t  {margin-top:0px}
    .lg-m-5px-t  {margin-top:5px}
    .lg-m-10px-t {margin-top:10px}
    .lg-m-15px-t {margin-top:15px}
    .lg-m-20px-t {margin-top:20px}
    .lg-m-25px-t {margin-top:25px}
    .lg-m-30px-t {margin-top:30px}
    .lg-m-35px-t {margin-top:35px}
    .lg-m-40px-t {margin-top:40px}
    .lg-m-45px-t {margin-top:45px}
    .lg-m-50px-t {margin-top:50px}
    .lg-m-55px-t {margin-top:55px}
    .lg-m-60px-t {margin-top:60px}
    .lg-m-65px-t {margin-top:65px}
    .lg-m-70px-t {margin-top:70px}
    .lg-m-75px-t {margin-top:75px}
    .lg-m-80px-t {margin-top:80px}
    .lg-m-85px-t {margin-top:85px}
    .lg-m-90px-t {margin-top:90px}
    .lg-m-95px-t {margin-top:95px}
    .lg-m-100px-t {margin-top:100px}

    /*Margin Bottom*/
    .lg-m-0px-b {margin-bottom:0px}
    .lg-m-5px-b {margin-bottom:5px}
    .lg-m-10px-b {margin-bottom:10px}
    .lg-m-15px-b {margin-bottom:15px}
    .lg-m-20px-b {margin-bottom:20px}
    .lg-m-25px-b {margin-bottom:25px}
    .lg-m-30px-b {margin-bottom:30px}
    .lg-m-35px-b {margin-bottom:35px}
    .lg-m-40px-b {margin-bottom:40px}
    .lg-m-45px-b {margin-bottom:45px}
    .lg-m-50px-b {margin-bottom:50px}
    .lg-m-55px-b {margin-bottom:55px}
    .lg-m-60px-b {margin-bottom:60px}
    .lg-m-65px-b {margin-bottom:65px}
    .lg-m-70px-b {margin-bottom:70px}
    .lg-m-75px-b {margin-bottom:75px}
    .lg-m-80px-b {margin-bottom:80px}
    .lg-m-85px-b {margin-bottom:85px}
    .lg-m-90px-b {margin-bottom:90px}
    .lg-m-95px-b {margin-bottom:95px}
    .lg-m-100px-b {margin-bottom:100px}

    /*Margin Left*/
    .lg-m-0px-l  {margin-left:0px}
    .lg-m-5px-l  {margin-left:5px}
    .lg-m-10px-l {margin-left:10px}
    .lg-m-15px-l {margin-left:15px}
    .lg-m-20px-l {margin-left:20px}
    .lg-m-25px-l {margin-left:25px}
    .lg-m-30px-l {margin-left:30px}
    .lg-m-35px-l {margin-left:35px}
    .lg-m-40px-l {margin-left:40px}
    .lg-m-45px-l {margin-left:45px}
    .lg-m-50px-l {margin-left:50px}
    .lg-m-55px-l {margin-left:55px}
    .lg-m-60px-l {margin-left:60px}
    .lg-m-65px-l {margin-left:65px}
    .lg-m-70px-l {margin-left:70px}
    .lg-m-75px-l {margin-left:75px}
    .lg-m-80px-l {margin-left:80px}
    .lg-m-85px-l {margin-left:85px}
    .lg-m-90px-l {margin-left:90px}
    .lg-m-95px-l {margin-left:95px}
    .lg-m-100px-l {margin-left:100px}

    /*Margin right*/
    .lg-m-0px-r  {margin-right:0px}
    .lg-m-5px-r  {margin-right:5px}
    .lg-m-10px-r {margin-right:10px}
    .lg-m-15px-r {margin-right:15px}
    .lg-m-20px-r {margin-right:20px}
    .lg-m-25px-r {margin-right:25px}
    .lg-m-30px-r {margin-right:30px}
    .lg-m-35px-r {margin-right:35px}
    .lg-m-40px-r {margin-right:40px}
    .lg-m-45px-r {margin-right:45px}
    .lg-m-50px-r {margin-right:50px}
    .lg-m-55px-r {margin-right:55px}
    .lg-m-60px-r {margin-right:60px}
    .lg-m-65px-r {margin-right:65px}
    .lg-m-70px-r {margin-right:70px}
    .lg-m-75px-r {margin-right:75px}
    .lg-m-80px-r {margin-right:80px}
    .lg-m-85px-r {margin-right:85px}
    .lg-m-90px-r {margin-right:90px}
    .lg-m-95px-r {margin-right:95px}
    .lg-m-100px-r {margin-right:100px}
}

@media (max-width: 991px){
    /*Margin All*/
    .md-m-0px { margin: 0;}
    .md-m-5px {margin:5px}
    .md-m-10px {margin:10px}
    .md-m-15px {margin:15px}
    .md-m-20px {margin:20px}
    .md-m-25px {margin:25px}
    .md-m-30px {margin:30px}
    .md-m-35px {margin:35px}
    .md-m-40px {margin:40px}
    .md-m-45px {margin:45px}
    .md-m-50px {margin:50px}
    .md-m-55px {margin:55px}
    .md-m-60px {margin:60px}
    .md-m-65px {margin:65px}
    .md-m-70px {margin:70px}
    .md-m-75px {margin:75px}
    .md-m-80px {margin:80px}
    .md-m-85px {margin:85px}
    .md-m-90px {margin:90px}
    .md-m-95px {margin:95px}
    .md-m-100px {margin:100px}

    /*Margin top buttom*/
    .md-m-0px-tb  {margin-top:0px;  margin-bottom:0px;}
    .md-m-5px-tb  {margin-top:5px;  margin-bottom:5px;}
    .md-m-10px-tb {margin-top:10px; margin-bottom:10px;}
    .md-m-15px-tb {margin-top:15px; margin-bottom:15px;}
    .md-m-20px-tb {margin-top:20px; margin-bottom:20px;}
    .md-m-25px-tb {margin-top:25px; margin-bottom:25px;}
    .md-m-30px-tb {margin-top:30px; margin-bottom:30px;}
    .md-m-35px-tb {margin-top:35px; margin-bottom:35px;}
    .md-m-40px-tb {margin-top:40px; margin-bottom:40px;}
    .md-m-45px-tb {margin-top:45px; margin-bottom:45px;}
    .md-m-50px-tb {margin-top:50px; margin-bottom:50px;}
    .md-m-55px-tb {margin-top:55px; margin-bottom:55px;}
    .md-m-60px-tb {margin-top:60px; margin-bottom:60px;}
    .md-m-65px-tb {margin-top:65px; margin-bottom:65px;}
    .md-m-70px-tb {margin-top:70px; margin-bottom:70px;}
    .md-m-75px-tb {margin-top:75px; margin-bottom:75px;}
    .md-m-80px-tb {margin-top:80px; margin-bottom:80px;}
    .md-m-85px-tb {margin-top:85px; margin-bottom:85px;}
    .md-m-90px-tb {margin-top:90px; margin-bottom:90px;}
    .md-m-95px-tb {margin-top:95px; margin-bottom:95px;}
    .md-m-100px-tb {margin-top:100px; margin-bottom: 100px;}

    /*Margin left right*/
    .md-m-0px-lr  {margin-left:0px; margin-right:0px}
    .md-m-5px-lr  {margin-left:5px; margin-right:5px}
    .md-m-10px-lr {margin-left:10px; margin-right:10px}
    .md-m-15px-lr {margin-left:15px; margin-right:15px}
    .md-m-20px-lr {margin-left:20px; margin-right:20px}
    .md-m-25px-lr {margin-left:25px; margin-right:25px}
    .md-m-30px-lr {margin-left:30px; margin-right:30px}
    .md-m-35px-lr {margin-left:35px; margin-right:35px}
    .md-m-40px-lr {margin-left:40px; margin-right:40px}
    .md-m-45px-lr {margin-left:45px; margin-right:45px}
    .md-m-50px-lr {margin-left:50px; margin-right:50px}
    .md-m-55px-lr {margin-left:55px; margin-right:55px}
    .md-m-60px-lr {margin-left:60px; margin-right:60px}
    .md-m-65px-lr {margin-left:65px; margin-right:65px}
    .md-m-70px-lr {margin-left:70px; margin-right:70px}
    .md-m-75px-lr {margin-left:75px; margin-right:75px}
    .md-m-80px-lr {margin-left:80px; margin-right:80px}
    .md-m-85px-lr {margin-left:85px; margin-right:85px}
    .md-m-90px-lr {margin-left:90px; margin-right:90px}
    .md-m-95px-lr {margin-left:95px; margin-right:95px}
    .md-m-100px-lr {margin-left:100px; margin-right:100px}


    /*Margin top*/
    .md-m-0px-t  {margin-top:0px}
    .md-m-5px-t  {margin-top:5px}
    .md-m-10px-t {margin-top:10px}
    .md-m-15px-t {margin-top:15px}
    .md-m-20px-t {margin-top:20px}
    .md-m-25px-t {margin-top:25px}
    .md-m-30px-t {margin-top:30px}
    .md-m-35px-t {margin-top:35px}
    .md-m-40px-t {margin-top:40px}
    .md-m-45px-t {margin-top:45px}
    .md-m-50px-t {margin-top:50px}
    .md-m-55px-t {margin-top:55px}
    .md-m-60px-t {margin-top:60px}
    .md-m-65px-t {margin-top:65px}
    .md-m-70px-t {margin-top:70px}
    .md-m-75px-t {margin-top:75px}
    .md-m-80px-t {margin-top:80px}
    .md-m-85px-t {margin-top:85px}
    .md-m-90px-t {margin-top:90px}
    .md-m-95px-t {margin-top:95px}
    .md-m-100px-t {margin-top:100px}

    /*Margin Bottom*/
    .md-m-0px-b {margin-bottom:0px}
    .md-m-5px-b {margin-bottom:5px}
    .md-m-10px-b {margin-bottom:10px}
    .md-m-15px-b {margin-bottom:15px}
    .md-m-20px-b {margin-bottom:20px}
    .md-m-25px-b {margin-bottom:25px}
    .md-m-30px-b {margin-bottom:30px}
    .md-m-35px-b {margin-bottom:35px}
    .md-m-40px-b {margin-bottom:40px}
    .md-m-45px-b {margin-bottom:45px}
    .md-m-50px-b {margin-bottom:50px}
    .md-m-55px-b {margin-bottom:55px}
    .md-m-60px-b {margin-bottom:60px}
    .md-m-65px-b {margin-bottom:65px}
    .md-m-70px-b {margin-bottom:70px}
    .md-m-75px-b {margin-bottom:75px}
    .md-m-80px-b {margin-bottom:80px}
    .md-m-85px-b {margin-bottom:85px}
    .md-m-90px-b {margin-bottom:90px}
    .md-m-95px-b {margin-bottom:95px}
    .md-m-100px-b {margin-bottom:100px}

    /*Margin Left*/
    .md-m-0px-l  {margin-left:0px}
    .md-m-5px-l  {margin-left:5px}
    .md-m-10px-l {margin-left:10px}
    .md-m-15px-l {margin-left:15px}
    .md-m-20px-l {margin-left:20px}
    .md-m-25px-l {margin-left:25px}
    .md-m-30px-l {margin-left:30px}
    .md-m-35px-l {margin-left:35px}
    .md-m-40px-l {margin-left:40px}
    .md-m-45px-l {margin-left:45px}
    .md-m-50px-l {margin-left:50px}
    .md-m-55px-l {margin-left:55px}
    .md-m-60px-l {margin-left:60px}
    .md-m-65px-l {margin-left:65px}
    .md-m-70px-l {margin-left:70px}
    .md-m-75px-l {margin-left:75px}
    .md-m-80px-l {margin-left:80px}
    .md-m-85px-l {margin-left:85px}
    .md-m-90px-l {margin-left:90px}
    .md-m-95px-l {margin-left:95px}
    .md-m-100px-l {margin-left:100px}

    /*Margin right*/
    .md-m-0px-r  {margin-right:0px}
    .md-m-5px-r  {margin-right:5px}
    .md-m-10px-r {margin-right:10px}
    .md-m-15px-r {margin-right:15px}
    .md-m-20px-r {margin-right:20px}
    .md-m-25px-r {margin-right:25px}
    .md-m-30px-r {margin-right:30px}
    .md-m-35px-r {margin-right:35px}
    .md-m-40px-r {margin-right:40px}
    .md-m-45px-r {margin-right:45px}
    .md-m-50px-r {margin-right:50px}
    .md-m-55px-r {margin-right:55px}
    .md-m-60px-r {margin-right:60px}
    .md-m-65px-r {margin-right:65px}
    .md-m-70px-r {margin-right:70px}
    .md-m-75px-r {margin-right:75px}
    .md-m-80px-r {margin-right:80px}
    .md-m-85px-r {margin-right:85px}
    .md-m-90px-r {margin-right:90px}
    .md-m-95px-r {margin-right:95px}
    .md-m-100px-r {margin-right:100px}
}


@media (max-width: 767px){
    /*Margin All*/
    .sm-m-0px { margin: 0; }
    .sm-m-5px {margin:5px}
    .sm-m-10px {margin:10px}
    .sm-m-15px {margin:15px}
    .sm-m-20px {margin:20px}
    .sm-m-25px {margin:25px}
    .sm-m-30px {margin:30px}
    .sm-m-35px {margin:35px}
    .sm-m-40px {margin:40px}
    .sm-m-45px {margin:45px}
    .sm-m-50px {margin:50px}
    .sm-m-55px {margin:55px}
    .sm-m-60px {margin:60px}
    .sm-m-65px {margin:65px}
    .sm-m-70px {margin:70px}
    .sm-m-75px {margin:75px}
    .sm-m-80px {margin:80px}
    .sm-m-85px {margin:85px}
    .sm-m-90px {margin:90px}
    .sm-m-95px {margin:95px}
    .sm-m-100px {margin:100px}

    /*Margin top buttom*/
    .sm-m-0px-tb  {margin-top:0px;  margin-bottom:0px;}
    .sm-m-5px-tb  {margin-top:5px;  margin-bottom:5px;}
    .sm-m-10px-tb {margin-top:10px; margin-bottom:10px;}
    .sm-m-15px-tb {margin-top:15px; margin-bottom:15px;}
    .sm-m-20px-tb {margin-top:20px; margin-bottom:20px;}
    .sm-m-25px-tb {margin-top:25px; margin-bottom:25px;}
    .sm-m-30px-tb {margin-top:30px; margin-bottom:30px;}
    .sm-m-35px-tb {margin-top:35px; margin-bottom:35px;}
    .sm-m-40px-tb {margin-top:40px; margin-bottom:40px;}
    .sm-m-45px-tb {margin-top:45px; margin-bottom:45px;}
    .sm-m-50px-tb {margin-top:50px; margin-bottom:50px;}
    .sm-m-55px-tb {margin-top:55px; margin-bottom:55px;}
    .sm-m-60px-tb {margin-top:60px; margin-bottom:60px;}
    .sm-m-65px-tb {margin-top:65px; margin-bottom:65px;}
    .sm-m-70px-tb {margin-top:70px; margin-bottom:70px;}
    .sm-m-75px-tb {margin-top:75px; margin-bottom:75px;}
    .sm-m-80px-tb {margin-top:80px; margin-bottom:80px;}
    .sm-m-85px-tb {margin-top:85px; margin-bottom:85px;}
    .sm-m-90px-tb {margin-top:90px; margin-bottom:90px;}
    .sm-m-95px-tb {margin-top:95px; margin-bottom:95px;}
    .sm-m-100px-tb {margin-top:100px; margin-bottom: 100px;}

    /*Margin left right*/
    .sm-m-0px-lr  {margin-left:0px; margin-right:0px}
    .sm-m-5px-lr  {margin-left:5px; margin-right:5px}
    .sm-m-10px-lr {margin-left:10px; margin-right:10px}
    .sm-m-15px-lr {margin-left:15px; margin-right:15px}
    .sm-m-20px-lr {margin-left:20px; margin-right:20px}
    .sm-m-25px-lr {margin-left:25px; margin-right:25px}
    .sm-m-30px-lr {margin-left:30px; margin-right:30px}
    .sm-m-35px-lr {margin-left:35px; margin-right:35px}
    .sm-m-40px-lr {margin-left:40px; margin-right:40px}
    .sm-m-45px-lr {margin-left:45px; margin-right:45px}
    .sm-m-50px-lr {margin-left:50px; margin-right:50px}
    .sm-m-55px-lr {margin-left:55px; margin-right:55px}
    .sm-m-60px-lr {margin-left:60px; margin-right:60px}
    .sm-m-65px-lr {margin-left:65px; margin-right:65px}
    .sm-m-70px-lr {margin-left:70px; margin-right:70px}
    .sm-m-75px-lr {margin-left:75px; margin-right:75px}
    .sm-m-80px-lr {margin-left:80px; margin-right:80px}
    .sm-m-85px-lr {margin-left:85px; margin-right:85px}
    .sm-m-90px-lr {margin-left:90px; margin-right:90px}
    .sm-m-95px-lr {margin-left:95px; margin-right:95px}
    .sm-m-100px-lr {margin-left:100px; margin-right:100px}

    /*Margin top*/
    .sm-m-0px-t  {margin-top:0px}
    .sm-m-5px-t  {margin-top:5px}
    .sm-m-10px-t {margin-top:10px}
    .sm-m-15px-t {margin-top:15px}
    .sm-m-20px-t {margin-top:20px}
    .sm-m-25px-t {margin-top:25px}
    .sm-m-30px-t {margin-top:30px}
    .sm-m-35px-t {margin-top:35px}
    .sm-m-40px-t {margin-top:40px}
    .sm-m-45px-t {margin-top:45px}
    .sm-m-50px-t {margin-top:50px}
    .sm-m-55px-t {margin-top:55px}
    .sm-m-60px-t {margin-top:60px}
    .sm-m-65px-t {margin-top:65px}
    .sm-m-70px-t {margin-top:70px}
    .sm-m-75px-t {margin-top:75px}
    .sm-m-80px-t {margin-top:80px}
    .sm-m-85px-t {margin-top:85px}
    .sm-m-90px-t {margin-top:90px}
    .sm-m-95px-t {margin-top:95px}
    .sm-m-100px-t {margin-top:100px}

    /*Margin Bottom*/
    .sm-m-0px-b {margin-bottom:0px}
    .sm-m-5px-b {margin-bottom:5px}
    .sm-m-10px-b {margin-bottom:10px}
    .sm-m-15px-b {margin-bottom:15px}
    .sm-m-20px-b {margin-bottom:20px}
    .sm-m-25px-b {margin-bottom:25px}
    .sm-m-30px-b {margin-bottom:30px}
    .sm-m-35px-b {margin-bottom:35px}
    .sm-m-40px-b {margin-bottom:40px}
    .sm-m-45px-b {margin-bottom:45px}
    .sm-m-50px-b {margin-bottom:50px}
    .sm-m-55px-b {margin-bottom:55px}
    .sm-m-60px-b {margin-bottom:60px}
    .sm-m-65px-b {margin-bottom:65px}
    .sm-m-70px-b {margin-bottom:70px}
    .sm-m-75px-b {margin-bottom:75px}
    .sm-m-80px-b {margin-bottom:80px}
    .sm-m-85px-b {margin-bottom:85px}
    .sm-m-90px-b {margin-bottom:90px}
    .sm-m-95px-b {margin-bottom:95px}
    .sm-m-100px-b {margin-bottom:100px}

    /*Margin Left*/
    .sm-m-0px-l  {margin-left:0px}
    .sm-m-5px-l  {margin-left:5px}
    .sm-m-10px-l {margin-left:10px}
    .sm-m-15px-l {margin-left:15px}
    .sm-m-20px-l {margin-left:20px}
    .sm-m-25px-l {margin-left:25px}
    .sm-m-30px-l {margin-left:30px}
    .sm-m-35px-l {margin-left:35px}
    .sm-m-40px-l {margin-left:40px}
    .sm-m-45px-l {margin-left:45px}
    .sm-m-50px-l {margin-left:50px}
    .sm-m-55px-l {margin-left:55px}
    .sm-m-60px-l {margin-left:60px}
    .sm-m-65px-l {margin-left:65px}
    .sm-m-70px-l {margin-left:70px}
    .sm-m-75px-l {margin-left:75px}
    .sm-m-80px-l {margin-left:80px}
    .sm-m-85px-l {margin-left:85px}
    .sm-m-90px-l {margin-left:90px}
    .sm-m-95px-l {margin-left:95px}
    .sm-m-100px-l {margin-left:100px}

    /*Margin right*/
    .sm-m-0px-r  {margin-right:0px}
    .sm-m-5px-r  {margin-right:5px}
    .sm-m-10px-r {margin-right:10px}
    .sm-m-15px-r {margin-right:15px}
    .sm-m-20px-r {margin-right:20px}
    .sm-m-25px-r {margin-right:25px}
    .sm-m-30px-r {margin-right:30px}
    .sm-m-35px-r {margin-right:35px}
    .sm-m-40px-r {margin-right:40px}
    .sm-m-45px-r {margin-right:45px}
    .sm-m-50px-r {margin-right:50px}
    .sm-m-55px-r {margin-right:55px}
    .sm-m-60px-r {margin-right:60px}
    .sm-m-65px-r {margin-right:65px}
    .sm-m-70px-r {margin-right:70px}
    .sm-m-75px-r {margin-right:75px}
    .sm-m-80px-r {margin-right:80px}
    .sm-m-85px-r {margin-right:85px}
    .sm-m-90px-r {margin-right:90px}
    .sm-m-95px-r {margin-right:95px}
    .sm-m-100px-r {margin-right:100px}
}

/*==============================
  17. Padding
================================*/

/*Padding All*/
.p-0px { padding: 0; }
.p-5px {padding: 5px}
.p-10px {padding:10px}
.p-15px {padding:15px}
.p-20px {padding:20px}
.p-25px {padding:25px}
.p-30px {padding:30px}
.p-35px {padding:35px}
.p-40px {padding:40px}
.p-45px {padding:45px}
.p-50px {padding:50px}
.p-55px {padding:55px}
.p-60px {padding:60px}
.p-65px {padding:65px}
.p-70px {padding:70px}
.p-75px {padding:75px}
.p-80px {padding:80px}
.p-85px {padding:85px}
.p-90px {padding:90px}
.p-95px {padding:95px}
.p-100px {padding:100px}

/*Padding Left Right*/
.p-0px-lr { padding-left: 0; ; padding-right: 0;  }
.p-5px-lr { padding-left: 5px; padding-right: 5px }
.p-10px-lr {padding-left:10px; padding-right:10px }
.p-15px-lr {padding-left:15px; padding-right:15px }
.p-20px-lr {padding-left:20px; padding-right:20px }
.p-25px-lr {padding-left:25px; padding-right:25px }
.p-30px-lr {padding-left:30px; padding-right:30px }
.p-35px-lr {padding-left:35px; padding-right:35px }
.p-40px-lr {padding-left:40px; padding-right:40px }
.p-45px-lr {padding-left:45px; padding-right:45px }
.p-50px-lr {padding-left:50px; padding-right:50px }
.p-55px-lr {padding-left:55px; padding-right:55px }
.p-60px-lr {padding-left:60px; padding-right:60px }
.p-65px-lr {padding-left:65px; padding-right:65px }
.p-70px-lr {padding-left:70px; padding-right:70px }
.p-75px-lr {padding-left:75px; padding-right:75px }
.p-80px-lr {padding-left:80px; padding-right:80px }
.p-85px-lr {padding-left:85px; padding-right:85px }
.p-90px-lr {padding-left:90px; padding-right:90px }
.p-95px-lr {padding-left:95px; padding-right:95px }
.p-100px-lr {padding-left:100px; padding-right:100px }

/*Padding Top Bottm*/
.p-0px-tb {padding-top:  0;   padding-bottom:  0;  }
.p-5px-tb {padding-top:  5px; padding-bottom:  5px }
.p-10px-tb {padding-top: 10px; padding-bottom: 10px }
.p-15px-tb {padding-top: 15px; padding-bottom: 15px }
.p-20px-tb {padding-top: 20px; padding-bottom: 20px }
.p-25px-tb {padding-top: 25px; padding-bottom: 25px }
.p-30px-tb {padding-top: 30px; padding-bottom: 30px }
.p-35px-tb {padding-top: 35px; padding-bottom: 35px }
.p-40px-tb {padding-top: 40px; padding-bottom: 40px }
.p-45px-tb {padding-top: 45px; padding-bottom: 45px }
.p-50px-tb {padding-top: 50px; padding-bottom: 50px }
.p-55px-tb {padding-top: 55px; padding-bottom: 55px }
.p-60px-tb {padding-top: 60px; padding-bottom: 60px }
.p-65px-tb {padding-top: 65px; padding-bottom: 65px }
.p-70px-tb {padding-top: 70px; padding-bottom: 70px }
.p-75px-tb {padding-top: 75px; padding-bottom: 75px }
.p-80px-tb {padding-top: 80px; padding-bottom: 80px }
.p-85px-tb {padding-top: 85px; padding-bottom: 85px }
.p-90px-tb {padding-top: 90px; padding-bottom: 90px }
.p-95px-tb {padding-top: 95px; padding-bottom: 95px }
.p-100px-tb {padding-top: 100px; padding-bottom: 100px }


/*Padding top*/
.p-0px-t  {padding-top:0px}
.p-5px-t  {padding-top:5px}
.p-10px-t {padding-top:10px}
.p-15px-t {padding-top:15px}
.p-20px-t {padding-top:20px}
.p-25px-t {padding-top:25px}
.p-30px-t {padding-top:30px}
.p-35px-t {padding-top:35px}
.p-40px-t {padding-top:40px}
.p-45px-t {padding-top:45px}
.p-50px-t {padding-top:50px}
.p-55px-t {padding-top:55px}
.p-60px-t {padding-top:60px}
.p-65px-t {padding-top:65px}
.p-70px-t {padding-top:70px}
.p-75px-t {padding-top:75px}
.p-80px-t {padding-top:80px}
.p-85px-t {padding-top:85px}
.p-90px-t {padding-top:90px}
.p-95px-t {padding-top:95px}
.p-100px-t {padding-top:100px}

/*Padding Bottom*/
.p-0px-b {padding-bottom:0px}
.p-5px-b {padding-bottom:5px}
.p-10px-b {padding-bottom:10px}
.p-15px-b {padding-bottom:15px}
.p-20px-b {padding-bottom:20px}
.p-25px-b {padding-bottom:25px}
.p-30px-b {padding-bottom:30px}
.p-35px-b {padding-bottom:35px}
.p-40px-b {padding-bottom:40px}
.p-45px-b {padding-bottom:45px}
.p-50px-b {padding-bottom:50px}
.p-55px-b {padding-bottom:55px}
.p-60px-b {padding-bottom:60px}
.p-65px-b {padding-bottom:65px}
.p-70px-b {padding-bottom:70px}
.p-75px-b {padding-bottom:75px}
.p-80px-b {padding-bottom:80px}
.p-85px-b {padding-bottom:85px}
.p-90px-b {padding-bottom:90px}
.p-95px-b {padding-bottom:95px}
.p-100px-b {padding-bottom:100px}

/*Padding Left*/
.p-0px-l  {padding-left:0px}
.p-5px-l  {padding-left:5px}
.p-10px-l {padding-left:10px}
.p-15px-l {padding-left:15px}
.p-20px-l {padding-left:20px}
.p-25px-l {padding-left:25px}
.p-30px-l {padding-left:30px}
.p-35px-l {padding-left:35px}
.p-40px-l {padding-left:40px}
.p-45px-l {padding-left:45px}
.p-50px-l {padding-left:50px}
.p-55px-l {padding-left:55px}
.p-60px-l {padding-left:60px}
.p-65px-l {padding-left:65px}
.p-70px-l {padding-left:70px}
.p-75px-l {padding-left:75px}
.p-80px-l {padding-left:80px}
.p-85px-l {padding-left:85px}
.p-90px-l {padding-left:90px}
.p-95px-l {padding-left:95px}
.p-100px-l {padding-left:100px}

/*Padding right*/
.p-0px-r  {padding-right:0px}
.p-5px-r  {padding-right:5px}
.p-10px-r {padding-right:10px}
.p-15px-r {padding-right:15px}
.p-20px-r {padding-right:20px}
.p-25px-r {padding-right:25px}
.p-30px-r {padding-right:30px}
.p-35px-r {padding-right:35px}
.p-40px-r {padding-right:40px}
.p-45px-r {padding-right:45px}
.p-50px-r {padding-right:50px}
.p-55px-r {padding-right:55px}
.p-60px-r {padding-right:60px}
.p-65px-r {padding-right:65px}
.p-70px-r {padding-right:70px}
.p-75px-r {padding-right:75px}
.p-80px-r {padding-right:80px}
.p-85px-r {padding-right:85px}
.p-90px-r {padding-right:90px}
.p-95px-r {padding-right:95px}
.p-100px-r {padding-right:100px}


/*Padding All %*/
.p-1 {padding:1%;}
.p-1-half {padding:1.5%;}
.p-2 {padding:2%;}
.p-2-half {padding:2.5%;}
.p-3 {padding:3%;}
.p-3-half {padding:3.5%;}
.p-4 {padding:4%;}
.p-4-half {padding:4.5%;}
.p-5 {padding:5%;}
.p-5-half {padding:5.5%;}
.p-6 {padding:6%;}
.p-6-half {padding:6.5%;}
.p-7 {padding:7%;}
.p-7-half {padding:7.5%;}
.p-8 {padding:8%;}
.p-8-half {padding:8.5%;}
.p-9 {padding:9%;}
.p-9-half {padding:9.5%;}
.p-10 {padding:10%;}
.p-10-half {padding:10.5%;}
.p-11 {padding:11%;}
.p-11-half {padding:11.5%;}
.p-12 {padding:12%;}
.p-12-half {padding:12.5%;}
.p-13 {padding:13%;}
.p-13-half {padding:13.5%;}
.p-14 {padding:14%;}
.p-14-half {padding:14.5%;}
.p-15 {padding:15%;}
.p-15-half {padding:15.5%;}
.p-16 {padding:16%;}
.p-16-half {padding:16.5%;}
.p-17 {padding:17%;}
.p-17-half {padding:17.5%;}
.p-18 {padding:18%;}
.p-18-half {padding:18.5%;}
.p-19 {padding:19%;}
.p-19-half {padding:19.5%;}
.p-20 {padding:20%;}
.p-20-half {padding:20.5%;}
.p-21 {padding:21%;}
.p-21-half {padding:21.5%;}
.p-22 {padding:22%;}
.p-22-half {padding:22.5%;}
.p-23 {padding:23%;}
.p-23-half {padding:23.5%;}
.p-24 {padding:24%;}
.p-24-half {padding:24.5%;}
.p-25 {padding:25%;}
.p-25-half {padding:25.5%;}

.p-1-l {padding-left:1%;}
.p-1-half-l {padding-left:1.5%;}
.p-2-l {padding-left:2%;}
.p-2-half-l {padding-left:2.5%;}
.p-3-l {padding-left:3%;}
.p-3-half-l {padding-left:3.5%;}
.p-4-l {padding-left:4%;}
.p-4-half-l {padding-left:4.5%;}
.p-5-l {padding-left:5%;}
.p-5-half-l {padding-left:5.5%;}
.p-6-l {padding-left:6%;}
.p-6-half-l {padding-left:6.5%;}
.p-7-l {padding-left:7%;}
.p-7-half-l {padding-left:7.5%;}
.p-8-l {padding-left:8%;}
.p-8-half-l {padding-left:8.5%;}
.p-9-l {padding-left:9%;}
.p-9-half-l {padding-left:9.5%;}
.p-10-l {padding-left:10%;}
.p-10-half-l {padding-left:10.5%;}
.p-11-l {padding-left:11%;}
.p-11-half-l {padding-left:11.5%;}
.p-12-l {padding-left:12%;}
.p-12-half-l {padding-left:12.5%;}
.p-13-l {padding-left:13%;}
.p-13-half-l {padding-left:13.5%;}
.p-14-l {padding-left:14%;}
.p-14-half-l {padding-left:14.5%;}
.p-15-l {padding-left:15%;}
.p-15-half-l {padding-left:15.5%;}
.p-16-l {padding-left:16%;}
.p-16-half-l {padding-left:16.5%;}
.p-17-l {padding-left:17%;}
.p-17-half-l {padding-left:17.5%;}
.p-18-l {padding-left:18%;}
.p-18-half-l {padding-left:18.5%;}
.p-19-l {padding-left:19%;}
.p-19-half-l {padding-left:19.5%;}
.p-20-l {padding-left:20%;}
.p-20-half-l {padding-left:20.5%;}
.p-21-l {padding-left:21%;}
.p-21-half-l {padding-left:21.5%;}
.p-22-l {padding-left:22%;}
.p-22-half-l {padding-left:22.5%;}
.p-23-l {padding-left:23%;}
.p-23-half-l {padding-left:23.5%;}
.p-24-l {padding-left:24%;}
.p-24-half-l {padding-left:24.5%;}
.p-25-l {padding-left:25%;}
.p-25-half-l {padding-left:25.5%;}


.p-1-r {padding-right: 1%;}
.p-1-half-r {padding-right: 1.5%;}
.p-2-r {padding-right: 2%;}
.p-2-half-r {padding-right: 2.5%;}
.p-3-r {padding-right: 3%;}
.p-3-half-r {padding-right: 3.5%;}
.p-4-r {padding-right: 4%;}
.p-4-half-r {padding-right: 4.5%;}
.p-5-r {padding-right: 5%;}
.p-5-half-r {padding-right: 5.5%;}
.p-6-r {padding-right: 6%;}
.p-6-half-r {padding-right: 6.5%;}
.p-7-r {padding-right: 7%;}
.p-7-half-r {padding-right: 7.5%;}
.p-8-r {padding-right: 8%;}
.p-8-half-r {padding-right: 8.5%;}
.p-9-r {padding-right: 9%;}
.p-9-half-r {padding-right: 9.5%;}
.p-10-r {padding-right: 10%;}
.p-10-half-r {padding-right: 10.5%;}
.p-11-r {padding-right: 11%;}
.p-11-half-r {padding-right: 11.5%;}
.p-12-r {padding-right: 12%;}
.p-12-half-r {padding-right: 12.5%;}
.p-13-r {padding-right: 13%;}
.p-13-half-r {padding-right: 13.5%;}
.p-14-r {padding-right: 14%;}
.p-14-half-r {padding-right: 14.5%;}
.p-15-r {padding-right: 15%;}
.p-15-half-r {padding-right: 15.5%;}
.p-16-r {padding-right: 16%;}
.p-16-half-r {padding-right: 16.5%;}
.p-17-r {padding-right: 17%;}
.p-17-half-r {padding-right: 17.5%;}
.p-18-r {padding-right: 18%;}
.p-18-half-r {padding-right: 18.5%;}
.p-19-r {padding-right: 19%;}
.p-19-half-r {padding-right: 19.5%;}
.p-20-r {padding-right: 20%;}
.p-20-half-r {padding-right: 20.5%;}
.p-21-r {padding-right: 21%;}
.p-21-half-r {padding-right: 21.5%;}
.p-22-r {padding-right: 22%;}
.p-22-half-r {padding-right: 22.5%;}
.p-23-r {padding-right: 23%;}
.p-23-half-r {padding-right: 23.5%;}
.p-24-r {padding-right: 24%;}
.p-24-half-r {padding-right: 24.5%;}
.p-25-r {padding-right: 25%;}
.p-25-half-r {padding-right: 25.5%;}

.p-1-t {padding-top: 1%;}
.p-1-half-t {padding-top: 1.5%;}
.p-2-t {padding-top: 2%;}
.p-2-half-t {padding-top: 2.5%;}
.p-3-t {padding-top: 3%;}
.p-3-half-t {padding-top: 3.5%;}
.p-4-t {padding-top: 4%;}
.p-4-half-t {padding-top: 4.5%;}
.p-5-t {padding-top: 5%;}
.p-5-half-t {padding-top: 5.5%;}
.p-6-t {padding-top: 6%;}
.p-6-half-t {padding-top: 6.5%;}
.p-7-t {padding-top: 7%;}
.p-7-half-t {padding-top: 7.5%;}
.p-8-t {padding-top: 8%;}
.p-8-half-t {padding-top: 8.5%;}
.p-9-t {padding-top: 9%;}
.p-9-half-t {padding-top: 9.5%;}
.p-10-t {padding-top: 10%;}
.p-10-half-t {padding-top: 10.5%;}
.p-11-t {padding-top: 11%;}
.p-11-half-t {padding-top: 11.5%;}
.p-12-t {padding-top: 12%;}
.p-12-half-t {padding-top: 12.5%;}
.p-13-t {padding-top: 13%;}
.p-13-half-t {padding-top: 13.5%;}
.p-14-t {padding-top: 14%;}
.p-14-half-t {padding-top: 14.5%;}
.p-15-t {padding-top: 15%;}
.p-15-half-t {padding-top: 15.5%;}
.p-16-t {padding-top: 16%;}
.p-16-half-t {padding-top: 16.5%;}
.p-17-t {padding-top: 17%;}
.p-17-half-t {padding-top: 17.5%;}
.p-18-t {padding-top: 18%;}
.p-18-half-t {padding-top: 18.5%;}
.p-19-t {padding-top: 19%;}
.p-19-half-t {padding-top: 19.5%;}
.p-20-t {padding-top: 20%;}
.p-20-half-t {padding-top: 20.5%;}
.p-21-t {padding-top: 21%;}
.p-21-half-t {padding-top: 21.5%;}
.p-22-t {padding-top: 22%;}
.p-22-half-t {padding-top: 22.5%;}
.p-23-t {padding-top: 23%;}
.p-23-half-t {padding-top: 23.5%;}
.p-24-t {padding-top: 24%;}
.p-24-half-t {padding-top: 24.5%;}
.p-25-t {padding-top: 25%;}
.p-25-half-t {padding-top: 25.5%;}

.p-1-b {padding-bottom: 1%;}
.p-1-half-b {padding-bottom: 1.5%;}
.p-2-b {padding-bottom: 2%;}
.p-2-half-b {padding-bottom: 2.5%;}
.p-3-b {padding-bottom: 3%;}
.p-3-half-b {padding-bottom: 3.5%;}
.p-4-b {padding-bottom: 4%;}
.p-4-half-b {padding-bottom: 4.5%;}
.p-5-b {padding-bottom: 5%;}
.p-5-half-b {padding-bottom: 5.5%;}
.p-6-b {padding-bottom: 6%;}
.p-6-half-b {padding-bottom: 6.5%;}
.p-7-b {padding-bottom: 7%;}
.p-7-half-b {padding-bottom: 7.5%;}
.p-8-b {padding-bottom: 8%;}
.p-8-half-b {padding-bottom: 8.5%;}
.p-9-b {padding-bottom: 9%;}
.p-9-half-b {padding-bottom: 9.5%;}
.p-10-b {padding-bottom: 10%;}
.p-10-half-b {padding-bottom: 10.5%;}
.p-11-b {padding-bottom: 11%;}
.p-11-half-b {padding-bottom: 11.5%;}
.p-12-b {padding-bottom: 12%;}
.p-12-half-b {padding-bottom: 12.5%;}
.p-13-b {padding-bottom: 13%;}
.p-13-half-b {padding-bottom: 13.5%;}
.p-14-b {padding-bottom: 14%;}
.p-14-half-b {padding-bottom: 14.5%;}
.p-15-b {padding-bottom: 15%;}
.p-15-half-b {padding-bottom: 15.5%;}
.p-16-b {padding-bottom: 16%;}
.p-16-half-b {padding-bottom: 16.5%;}
.p-17-b {padding-bottom: 17%;}
.p-17-half-b {padding-bottom: 17.5%;}
.p-18-b {padding-bottom: 18%;}
.p-18-half-b {padding-bottom: 18.5%;}
.p-19-b {padding-bottom: 19%;}
.p-19-half-b {padding-bottom: 19.5%;}
.p-20-b {padding-bottom: 20%;}
.p-20-half-b {padding-bottom: 20.5%;}
.p-21-b {padding-bottom: 21%;}
.p-21-half-b {padding-bottom: 21.5%;}
.p-22-b {padding-bottom: 22%;}
.p-22-half-b {padding-bottom: 22.5%;}
.p-23-b {padding-bottom: 23%;}
.p-23-half-b {padding-bottom: 23.5%;}
.p-24-b {padding-bottom: 24%;}
.p-24-half-b {padding-bottom: 24.5%;}
.p-25-b {padding-bottom: 25%;}
.p-25-half-b {padding-bottom: 25.5%;}

@media (max-width: 1200px){
    .lg-p-0px { padding: 0; }
    .lg-p-5px {padding: 5px}
    .lg-p-10px {padding:10px}
    .lg-p-15px {padding:15px}
    .lg-p-20px {padding:20px}
    .lg-p-25px {padding:25px}
    .lg-p-30px {padding:30px}
    .lg-p-35px {padding:35px}
    .lg-p-40px {padding:40px}
    .lg-p-45px {padding:45px}
    .lg-p-50px {padding:50px}
    .lg-p-55px {padding:55px}
    .lg-p-60px {padding:60px}
    .lg-p-65px {padding:65px}
    .lg-p-70px {padding:70px}
    .lg-p-75px {padding:75px}
    .lg-p-80px {padding:80px}
    .lg-p-85px {padding:85px}
    .lg-p-90px {padding:90px}
    .lg-p-95px {padding:95px}
    .lg-p-100px {padding:100px}

    /*Padding Left Right*/
    .lg-p-0px-lr { padding-left: 0; ; padding-right: 0;  }
    .lg-p-5px-lr { padding-left: 5px; padding-right: 5px }
    .lg-p-10px-lr {padding-left:10px; padding-right:10px }
    .lg-p-15px-lr {padding-left:15px; padding-right:15px }
    .lg-p-20px-lr {padding-left:20px; padding-right:20px }
    .lg-p-25px-lr {padding-left:25px; padding-right:25px }
    .lg-p-30px-lr {padding-left:30px; padding-right:30px }
    .lg-p-35px-lr {padding-left:35px; padding-right:35px }
    .lg-p-40px-lr {padding-left:40px; padding-right:40px }
    .lg-p-45px-lr {padding-left:45px; padding-right:45px }
    .lg-p-50px-lr {padding-left:50px; padding-right:50px }
    .lg-p-55px-lr {padding-left:55px; padding-right:55px }
    .lg-p-60px-lr {padding-left:60px; padding-right:60px }
    .lg-p-65px-lr {padding-left:65px; padding-right:65px }
    .lg-p-70px-lr {padding-left:70px; padding-right:70px }
    .lg-p-75px-lr {padding-left:75px; padding-right:75px }
    .lg-p-80px-lr {padding-left:80px; padding-right:80px }
    .lg-p-85px-lr {padding-left:85px; padding-right:85px }
    .lg-p-90px-lr {padding-left:90px; padding-right:90px }
    .lg-p-95px-lr {padding-left:95px; padding-right:95px }
    .lg-p-100px-lr {padding-left:100px; padding-right:100px }

    /*Padding Top Bottm*/
    .lg-p-0px-tb {padding-top:  0;   padding-bottom:  0;  }
    .lg-p-5px-tb {padding-top:  5px; padding-bottom:  5px }
    .lg-p-10px-tb {padding-top: 10px; padding-bottom: 10px }
    .lg-p-15px-tb {padding-top: 15px; padding-bottom: 15px }
    .lg-p-20px-tb {padding-top: 20px; padding-bottom: 20px }
    .lg-p-25px-tb {padding-top: 25px; padding-bottom: 25px }
    .lg-p-30px-tb {padding-top: 30px; padding-bottom: 30px }
    .lg-p-35px-tb {padding-top: 35px; padding-bottom: 35px }
    .lg-p-40px-tb {padding-top: 40px; padding-bottom: 40px }
    .lg-p-45px-tb {padding-top: 45px; padding-bottom: 45px }
    .lg-p-50px-tb {padding-top: 50px; padding-bottom: 50px }
    .lg-p-55px-tb {padding-top: 55px; padding-bottom: 55px }
    .lg-p-60px-tb {padding-top: 60px; padding-bottom: 60px }
    .lg-p-65px-tb {padding-top: 65px; padding-bottom: 65px }
    .lg-p-70px-tb {padding-top: 70px; padding-bottom: 70px }
    .lg-p-75px-tb {padding-top: 75px; padding-bottom: 75px }
    .lg-p-80px-tb {padding-top: 80px; padding-bottom: 80px }
    .lg-p-85px-tb {padding-top: 85px; padding-bottom: 85px }
    .lg-p-90px-tb {padding-top: 90px; padding-bottom: 90px }
    .lg-p-95px-tb {padding-top: 95px; padding-bottom: 95px }
    .lg-p-100px-tb {padding-top: 100px; padding-bottom: 100px }

    /*Padding top*/
    .lg-p-5px-0  {padding-top:0px}
    .lg-p-5px-t  {padding-top:5px}
    .lg-p-10px-t {padding-top:10px}
    .lg-p-15px-t {padding-top:15px}
    .lg-p-20px-t {padding-top:20px}
    .lg-p-25px-t {padding-top:25px}
    .lg-p-30px-t {padding-top:30px}
    .lg-p-35px-t {padding-top:35px}
    .lg-p-40px-t {padding-top:40px}
    .lg-p-45px-t {padding-top:45px}
    .lg-p-50px-t {padding-top:50px}
    .lg-p-55px-t {padding-top:55px}
    .lg-p-60px-t {padding-top:60px}
    .lg-p-65px-t {padding-top:65px}
    .lg-p-70px-t {padding-top:70px}
    .lg-p-75px-t {padding-top:75px}
    .lg-p-80px-t {padding-top:80px}
    .lg-p-85px-t {padding-top:85px}
    .lg-p-90px-t {padding-top:90px}
    .lg-p-95px-t {padding-top:95px}
    .lg-p-100px-t {padding-top:100px}

    /*Padding Bottom*/
    .lg-p-0px-b {padding-bottom:0px}
    .lg-p-5px-b {padding-bottom:5px}
    .lg-p-10px-b {padding-bottom:10px}
    .lg-p-15px-b {padding-bottom:15px}
    .lg-p-20px-b {padding-bottom:20px}
    .lg-p-25px-b {padding-bottom:25px}
    .lg-p-30px-b {padding-bottom:30px}
    .lg-p-35px-b {padding-bottom:35px}
    .lg-p-40px-b {padding-bottom:40px}
    .lg-p-45px-b {padding-bottom:45px}
    .lg-p-50px-b {padding-bottom:50px}
    .lg-p-55px-b {padding-bottom:55px}
    .lg-p-60px-b {padding-bottom:60px}
    .lg-p-65px-b {padding-bottom:65px}
    .lg-p-70px-b {padding-bottom:70px}
    .lg-p-75px-b {padding-bottom:75px}
    .lg-p-80px-b {padding-bottom:80px}
    .lg-p-85px-b {padding-bottom:85px}
    .lg-p-90px-b {padding-bottom:90px}
    .lg-p-95px-b {padding-bottom:95px}
    .lg-p-100px-b {padding-bottom:100px}

    /*Padding Left*/
    .lg-p-0px-l  {padding-left:0px}
    .lg-p-5px-l  {padding-left:5px}
    .lg-p-10px-l {padding-left:10px}
    .lg-p-15px-l {padding-left:15px}
    .lg-p-20px-l {padding-left:20px}
    .lg-p-25px-l {padding-left:25px}
    .lg-p-30px-l {padding-left:30px}
    .lg-p-35px-l {padding-left:35px}
    .lg-p-40px-l {padding-left:40px}
    .lg-p-45px-l {padding-left:45px}
    .lg-p-50px-l {padding-left:50px}
    .lg-p-55px-l {padding-left:55px}
    .lg-p-60px-l {padding-left:60px}
    .lg-p-65px-l {padding-left:65px}
    .lg-p-70px-l {padding-left:70px}
    .lg-p-75px-l {padding-left:75px}
    .lg-p-80px-l {padding-left:80px}
    .lg-p-85px-l {padding-left:85px}
    .lg-p-90px-l {padding-left:90px}
    .lg-p-95px-l {padding-left:95px}
    .lg-p-100px-l {padding-left:100px}

    /*Padding right*/
    .lg-p-0px-r  {padding-left:0px}
    .lg-p-5px-r  {padding-left:5px}
    .lg-p-10px-r {padding-left:10px}
    .lg-p-15px-r {padding-left:15px}
    .lg-p-20px-r {padding-left:20px}
    .lg-p-25px-r {padding-left:25px}
    .lg-p-30px-r {padding-left:30px}
    .lg-p-35px-r {padding-left:35px}
    .lg-p-40px-r {padding-left:40px}
    .lg-p-45px-r {padding-left:45px}
    .lg-p-50px-r {padding-left:50px}
    .lg-p-55px-r {padding-left:55px}
    .lg-p-60px-r {padding-left:60px}
    .lg-p-65px-r {padding-left:65px}
    .lg-p-70px-r {padding-left:70px}
    .lg-p-75px-r {padding-left:75px}
    .lg-p-80px-r {padding-left:80px}
    .lg-p-85px-r {padding-left:85px}
    .lg-p-90px-r {padding-left:90px}
    .lg-p-95px-r {padding-left:95px}
    .lg-p-100px-r {padding-left:100px}

    /*Padding All %*/
    .lg-p-1 {padding:1%;}
    .lg-p-1-half {padding:1.5%;}
    .lg-p-2 {padding:2%;}
    .lg-p-2-half {padding:2.5%;}
    .lg-p-3 {padding:3%;}
    .lg-p-3-half {padding:3.5%;}
    .lg-p-4 {padding:4%;}
    .lg-p-4-half {padding:4.5%;}
    .lg-p-5 {padding:5%;}
    .lg-p-5-half {padding:5.5%;}
    .lg-p-6 {padding:6%;}
    .lg-p-6-half {padding:6.5%;}
    .lg-p-7 {padding:7%;}
    .lg-p-7-half {padding:7.5%;}
    .lg-p-8 {padding:8%;}
    .lg-p-8-half {padding:8.5%;}
    .lg-p-9 {padding:9%;}
    .lg-p-9-half {padding:9.5%;}
    .lg-p-10 {padding:10%;}
    .lg-p-10-half {padding:10.5%;}
    .lg-p-11 {padding:11%;}
    .lg-p-11-half {padding:11.5%;}
    .lg-p-12 {padding:12%;}
    .lg-p-12-half {padding:12.5%;}
    .lg-p-13 {padding:13%;}
    .lg-p-13-half {padding:13.5%;}
    .lg-p-14 {padding:14%;}
    .lg-p-14-half {padding:14.5%;}
    .lg-p-15 {padding:15%;}
    .lg-p-15-half {padding:15.5%;}
    .lg-p-16 {padding:16%;}
    .lg-p-16-half {padding:16.5%;}
    .lg-p-17 {padding:17%;}
    .lg-p-17-half {padding:17.5%;}
    .lg-p-18 {padding:18%;}
    .lg-p-18-half {padding:18.5%;}
    .lg-p-19 {padding:19%;}
    .lg-p-19-half {padding:19.5%;}
    .lg-p-20 {padding:20%;}
    .lg-p-20-half {padding:20.5%;}
    .lg-p-21 {padding:21%;}
    .lg-p-21-half {padding:21.5%;}
    .lg-p-22 {padding:22%;}
    .lg-p-22-half {padding:22.5%;}
    .lg-p-23 {padding:23%;}
    .lg-p-23-half {padding:23.5%;}
    .lg-p-24 {padding:24%;}
    .lg-p-24-half {padding:24.5%;}
    .lg-p-25 {padding:25%;}
    .lg-p-25-half {padding:25.5%;}

    .lg-p-1-l {padding-left:1%;}
    .lg-p-1-half-l {padding-left:1.5%;}
    .lg-p-2-l {padding-left:2%;}
    .lg-p-2-half-l {padding-left:2.5%;}
    .lg-p-3-l {padding-left:3%;}
    .lg-p-3-half-l {padding-left:3.5%;}
    .lg-p-4-l {padding-left:4%;}
    .lg-p-4-half-l {padding-left:4.5%;}
    .lg-p-5-l {padding-left:5%;}
    .lg-p-5-half-l {padding-left:5.5%;}
    .lg-p-6-l {padding-left:6%;}
    .lg-p-6-half-l {padding-left:6.5%;}
    .lg-p-7-l {padding-left:7%;}
    .lg-p-7-half-l {padding-left:7.5%;}
    .lg-p-8-l {padding-left:8%;}
    .lg-p-8-half-l {padding-left:8.5%;}
    .lg-p-9-l {padding-left:9%;}
    .lg-p-9-half-l {padding-left:9.5%;}
    .lg-p-10-l {padding-left:10%;}
    .lg-p-10-half-l {padding-left:10.5%;}
    .lg-p-11-l {padding-left:11%;}
    .lg-p-11-half-l {padding-left:11.5%;}
    .lg-p-12-l {padding-left:12%;}
    .lg-p-12-half-l {padding-left:12.5%;}
    .lg-p-13-l {padding-left:13%;}
    .lg-p-13-half-l {padding-left:13.5%;}
    .lg-p-14-l {padding-left:14%;}
    .lg-p-14-half-l {padding-left:14.5%;}
    .lg-p-15-l {padding-left:15%;}
    .lg-p-15-half-l {padding-left:15.5%;}
    .lg-p-16-l {padding-left:16%;}
    .lg-p-16-half-l {padding-left:16.5%;}
    .lg-p-17-l {padding-left:17%;}
    .lg-p-17-half-l {padding-left:17.5%;}
    .lg-p-18-l {padding-left:18%;}
    .lg-p-18-half-l {padding-left:18.5%;}
    .lg-p-19-l {padding-left:19%;}
    .lg-p-19-half-l {padding-left:19.5%;}
    .lg-p-20-l {padding-left:20%;}
    .lg-p-20-half-l {padding-left:20.5%;}
    .lg-p-21-l {padding-left:21%;}
    .lg-p-21-half-l {padding-left:21.5%;}
    .lg-p-22-l {padding-left:22%;}
    .lg-p-22-half-l {padding-left:22.5%;}
    .lg-p-23-l {padding-left:23%;}
    .lg-p-23-half-l {padding-left:23.5%;}
    .lg-p-24-l {padding-left:24%;}
    .lg-p-24-half-l {padding-left:24.5%;}
    .lg-p-25-l {padding-left:25%;}
    .lg-p-25-half-l {padding-left:25.5%;}


    .lg-p-1-r {padding-right: 1%;}
    .lg-p-1-half-r {padding-right: 1.5%;}
    .lg-p-2-r {padding-right: 2%;}
    .lg-p-2-half-r {padding-right: 2.5%;}
    .lg-p-3-r {padding-right: 3%;}
    .lg-p-3-half-r {padding-right: 3.5%;}
    .lg-p-4-r {padding-right: 4%;}
    .lg-p-4-half-r {padding-right: 4.5%;}
    .lg-p-5-r {padding-right: 5%;}
    .lg-p-5-half-r {padding-right: 5.5%;}
    .lg-p-6-r {padding-right: 6%;}
    .lg-p-6-half-r {padding-right: 6.5%;}
    .lg-p-7-r {padding-right: 7%;}
    .lg-p-7-half-r {padding-right: 7.5%;}
    .lg-p-8-r {padding-right: 8%;}
    .lg-p-8-half-r {padding-right: 8.5%;}
    .lg-p-9-r {padding-right: 9%;}
    .lg-p-9-half-r {padding-right: 9.5%;}
    .lg-p-10-r {padding-right: 10%;}
    .lg-p-10-half-r {padding-right: 10.5%;}
    .lg-p-11-r {padding-right: 11%;}
    .lg-p-11-half-r {padding-right: 11.5%;}
    .lg-p-12-r {padding-right: 12%;}
    .lg-p-12-half-r {padding-right: 12.5%;}
    .lg-p-13-r {padding-right: 13%;}
    .lg-p-13-half-r {padding-right: 13.5%;}
    .lg-p-14-r {padding-right: 14%;}
    .lg-p-14-half-r {padding-right: 14.5%;}
    .lg-p-15-r {padding-right: 15%;}
    .lg-p-15-half-r {padding-right: 15.5%;}
    .lg-p-16-r {padding-right: 16%;}
    .lg-p-16-half-r {padding-right: 16.5%;}
    .lg-p-17-r {padding-right: 17%;}
    .lg-p-17-half-r {padding-right: 17.5%;}
    .lg-p-18-r {padding-right: 18%;}
    .lg-p-18-half-r {padding-right: 18.5%;}
    .lg-p-19-r {padding-right: 19%;}
    .lg-p-19-half-r {padding-right: 19.5%;}
    .lg-p-20-r {padding-right: 20%;}
    .lg-p-20-half-r {padding-right: 20.5%;}
    .lg-p-21-r {padding-right: 21%;}
    .lg-p-21-half-r {padding-right: 21.5%;}
    .lg-p-22-r {padding-right: 22%;}
    .lg-p-22-half-r {padding-right: 22.5%;}
    .lg-p-23-r {padding-right: 23%;}
    .lg-p-23-half-r {padding-right: 23.5%;}
    .lg-p-24-r {padding-right: 24%;}
    .lg-p-24-half-r {padding-right: 24.5%;}
    .lg-p-25-r {padding-right: 25%;}
    .lg-p-25-half-r {padding-right: 25.5%;}

    .lg-p-1-t {padding-top: 1%;}
    .lg-p-1-half-t {padding-top: 1.5%;}
    .lg-p-2-t {padding-top: 2%;}
    .lg-p-2-half-t {padding-top: 2.5%;}
    .lg-p-3-t {padding-top: 3%;}
    .lg-p-3-half-t {padding-top: 3.5%;}
    .lg-p-4-t {padding-top: 4%;}
    .lg-p-4-half-t {padding-top: 4.5%;}
    .lg-p-5-t {padding-top: 5%;}
    .lg-p-5-half-t {padding-top: 5.5%;}
    .lg-p-6-t {padding-top: 6%;}
    .lg-p-6-half-t {padding-top: 6.5%;}
    .lg-p-7-t {padding-top: 7%;}
    .lg-p-7-half-t {padding-top: 7.5%;}
    .lg-p-8-t {padding-top: 8%;}
    .lg-p-8-half-t {padding-top: 8.5%;}
    .lg-p-9-t {padding-top: 9%;}
    .lg-p-9-half-t {padding-top: 9.5%;}
    .lg-p-10-t {padding-top: 10%;}
    .lg-p-10-half-t {padding-top: 10.5%;}
    .lg-p-11-t {padding-top: 11%;}
    .lg-p-11-half-t {padding-top: 11.5%;}
    .lg-p-12-t {padding-top: 12%;}
    .lg-p-12-half-t {padding-top: 12.5%;}
    .lg-p-13-t {padding-top: 13%;}
    .lg-p-13-half-t {padding-top: 13.5%;}
    .lg-p-14-t {padding-top: 14%;}
    .lg-p-14-half-t {padding-top: 14.5%;}
    .lg-p-15-t {padding-top: 15%;}
    .lg-p-15-half-t {padding-top: 15.5%;}
    .lg-p-16-t {padding-top: 16%;}
    .lg-p-16-half-t {padding-top: 16.5%;}
    .lg-p-17-t {padding-top: 17%;}
    .lg-p-17-half-t {padding-top: 17.5%;}
    .lg-p-18-t {padding-top: 18%;}
    .lg-p-18-half-t {padding-top: 18.5%;}
    .lg-p-19-t {padding-top: 19%;}
    .lg-p-19-half-t {padding-top: 19.5%;}
    .lg-p-20-t {padding-top: 20%;}
    .lg-p-20-half-t {padding-top: 20.5%;}
    .lg-p-21-t {padding-top: 21%;}
    .lg-p-21-half-t {padding-top: 21.5%;}
    .lg-p-22-t {padding-top: 22%;}
    .lg-p-22-half-t {padding-top: 22.5%;}
    .lg-p-23-t {padding-top: 23%;}
    .lg-p-23-half-t {padding-top: 23.5%;}
    .lg-p-24-t {padding-top: 24%;}
    .lg-p-24-half-t {padding-top: 24.5%;}
    .lg-p-25-t {padding-top: 25%;}
    .lg-p-25-half-t {padding-top: 25.5%;}

    .lg-p-1-b {padding-bottom: 1%;}
    .lg-p-1-half-b {padding-bottom: 1.5%;}
    .lg-p-2-b {padding-bottom: 2%;}
    .lg-p-2-half-b {padding-bottom: 2.5%;}
    .lg-p-3-b {padding-bottom: 3%;}
    .lg-p-3-half-b {padding-bottom: 3.5%;}
    .lg-p-4-b {padding-bottom: 4%;}
    .lg-p-4-half-b {padding-bottom: 4.5%;}
    .lg-p-5-b {padding-bottom: 5%;}
    .lg-p-5-half-b {padding-bottom: 5.5%;}
    .lg-p-6-b {padding-bottom: 6%;}
    .lg-p-6-half-b {padding-bottom: 6.5%;}
    .lg-p-7-b {padding-bottom: 7%;}
    .lg-p-7-half-b {padding-bottom: 7.5%;}
    .lg-p-8-b {padding-bottom: 8%;}
    .lg-p-8-half-b {padding-bottom: 8.5%;}
    .lg-p-9-b {padding-bottom: 9%;}
    .lg-p-9-half-b {padding-bottom: 9.5%;}
    .lg-p-10-b {padding-bottom: 10%;}
    .lg-p-10-half-b {padding-bottom: 10.5%;}
    .lg-p-11-b {padding-bottom: 11%;}
    .lg-p-11-half-b {padding-bottom: 11.5%;}
    .lg-p-12-b {padding-bottom: 12%;}
    .lg-p-12-half-b {padding-bottom: 12.5%;}
    .lg-p-13-b {padding-bottom: 13%;}
    .lg-p-13-half-b {padding-bottom: 13.5%;}
    .lg-p-14-b {padding-bottom: 14%;}
    .lg-p-14-half-b {padding-bottom: 14.5%;}
    .lg-p-15-b {padding-bottom: 15%;}
    .lg-p-15-half-b {padding-bottom: 15.5%;}
    .lg-p-16-b {padding-bottom: 16%;}
    .lg-p-16-half-b {padding-bottom: 16.5%;}
    .lg-p-17-b {padding-bottom: 17%;}
    .lg-p-17-half-b {padding-bottom: 17.5%;}
    .lg-p-18-b {padding-bottom: 18%;}
    .lg-p-18-half-b {padding-bottom: 18.5%;}
    .lg-p-19-b {padding-bottom: 19%;}
    .lg-p-19-half-b {padding-bottom: 19.5%;}
    .lg-p-20-b {padding-bottom: 20%;}
    .lg-p-20-half-b {padding-bottom: 20.5%;}
    .lg-p-21-b {padding-bottom: 21%;}
    .lg-p-21-half-b {padding-bottom: 21.5%;}
    .lg-p-22-b {padding-bottom: 22%;}
    .lg-p-22-half-b {padding-bottom: 22.5%;}
    .lg-p-23-b {padding-bottom: 23%;}
    .lg-p-23-half-b {padding-bottom: 23.5%;}
    .lg-p-24-b {padding-bottom: 24%;}
    .lg-p-24-half-b {padding-bottom: 24.5%;}
    .lg-p-25-b {padding-bottom: 25%;}
    .lg-p-25-half-b {padding-bottom: 25.5%;}
}


@media (max-width: 991px){
    .md-p-0px { padding: 0; }
    .md-p-5px {padding: 5px}
    .md-p-10px {padding:10px}
    .md-p-15px {padding:15px}
    .md-p-20px {padding:20px}
    .md-p-25px {padding:25px}
    .md-p-30px {padding:30px}
    .md-p-35px {padding:35px}
    .md-p-40px {padding:40px}
    .md-p-45px {padding:45px}
    .md-p-50px {padding:50px}
    .md-p-55px {padding:55px}
    .md-p-60px {padding:60px}
    .md-p-65px {padding:65px}
    .md-p-70px {padding:70px}
    .md-p-75px {padding:75px}
    .md-p-80px {padding:80px}
    .md-p-85px {padding:85px}
    .md-p-90px {padding:90px}
    .md-p-95px {padding:95px}
    .md-p-100px {padding:100px}

    /*Padding Left Right*/
    .md-p-0px-lr { padding-left: 0; ; padding-right: 0;  }
    .md-p-5px-lr { padding-left: 5px; padding-right: 5px }
    .md-p-10px-lr {padding-left:10px; padding-right:10px }
    .md-p-15px-lr {padding-left:15px; padding-right:15px }
    .md-p-20px-lr {padding-left:20px; padding-right:20px }
    .md-p-25px-lr {padding-left:25px; padding-right:25px }
    .md-p-30px-lr {padding-left:30px; padding-right:30px }
    .md-p-35px-lr {padding-left:35px; padding-right:35px }
    .md-p-40px-lr {padding-left:40px; padding-right:40px }
    .md-p-45px-lr {padding-left:45px; padding-right:45px }
    .md-p-50px-lr {padding-left:50px; padding-right:50px }
    .md-p-55px-lr {padding-left:55px; padding-right:55px }
    .md-p-60px-lr {padding-left:60px; padding-right:60px }
    .md-p-65px-lr {padding-left:65px; padding-right:65px }
    .md-p-70px-lr {padding-left:70px; padding-right:70px }
    .md-p-75px-lr {padding-left:75px; padding-right:75px }
    .md-p-80px-lr {padding-left:80px; padding-right:80px }
    .md-p-85px-lr {padding-left:85px; padding-right:85px }
    .md-p-90px-lr {padding-left:90px; padding-right:90px }
    .md-p-95px-lr {padding-left:95px; padding-right:95px }
    .md-p-100px-lr {padding-left:100px; padding-right:100px }

    /*Padding Top Bottm*/
    .md-p-0px-tb {padding-top:  0;   padding-bottom:  0;  }
    .md-p-5px-tb {padding-top:  5px; padding-bottom:  5px }
    .md-p-10px-tb {padding-top: 10px; padding-bottom: 10px }
    .md-p-15px-tb {padding-top: 15px; padding-bottom: 15px }
    .md-p-20px-tb {padding-top: 20px; padding-bottom: 20px }
    .md-p-25px-tb {padding-top: 25px; padding-bottom: 25px }
    .md-p-30px-tb {padding-top: 30px; padding-bottom: 30px }
    .md-p-35px-tb {padding-top: 35px; padding-bottom: 35px }
    .md-p-40px-tb {padding-top: 40px; padding-bottom: 40px }
    .md-p-45px-tb {padding-top: 45px; padding-bottom: 45px }
    .md-p-50px-tb {padding-top: 50px; padding-bottom: 50px }
    .md-p-55px-tb {padding-top: 55px; padding-bottom: 55px }
    .md-p-60px-tb {padding-top: 60px; padding-bottom: 60px }
    .md-p-65px-tb {padding-top: 65px; padding-bottom: 65px }
    .md-p-70px-tb {padding-top: 70px; padding-bottom: 70px }
    .md-p-75px-tb {padding-top: 75px; padding-bottom: 75px }
    .md-p-80px-tb {padding-top: 80px; padding-bottom: 80px }
    .md-p-85px-tb {padding-top: 85px; padding-bottom: 85px }
    .md-p-90px-tb {padding-top: 90px; padding-bottom: 90px }
    .md-p-95px-tb {padding-top: 95px; padding-bottom: 95px }
    .md-p-100px-tb {padding-top: 100px; padding-bottom: 100px }


    /*Padding top*/
    .md-p-0px-t  {padding-top:0px}
    .md-p-5px-t  {padding-top:5px}
    .md-p-10px-t {padding-top:10px}
    .md-p-15px-t {padding-top:15px}
    .md-p-20px-t {padding-top:20px}
    .md-p-25px-t {padding-top:25px}
    .md-p-30px-t {padding-top:30px}
    .md-p-35px-t {padding-top:35px}
    .md-p-40px-t {padding-top:40px}
    .md-p-45px-t {padding-top:45px}
    .md-p-50px-t {padding-top:50px}
    .md-p-55px-t {padding-top:55px}
    .md-p-60px-t {padding-top:60px}
    .md-p-65px-t {padding-top:65px}
    .md-p-70px-t {padding-top:70px}
    .md-p-75px-t {padding-top:75px}
    .md-p-80px-t {padding-top:80px}
    .md-p-85px-t {padding-top:85px}
    .md-p-90px-t {padding-top:90px}
    .md-p-95px-t {padding-top:95px}
    .md-p-100px-t {padding-top:100px}

    /*Padding Bottom*/
    .md-p-0px-b {padding-bottom:0px}
    .md-p-5px-b {padding-bottom:5px}
    .md-p-10px-b {padding-bottom:10px}
    .md-p-15px-b {padding-bottom:15px}
    .md-p-20px-b {padding-bottom:20px}
    .md-p-25px-b {padding-bottom:25px}
    .md-p-30px-b {padding-bottom:30px}
    .md-p-35px-b {padding-bottom:35px}
    .md-p-40px-b {padding-bottom:40px}
    .md-p-45px-b {padding-bottom:45px}
    .md-p-50px-b {padding-bottom:50px}
    .md-p-55px-b {padding-bottom:55px}
    .md-p-60px-b {padding-bottom:60px}
    .md-p-65px-b {padding-bottom:65px}
    .md-p-70px-b {padding-bottom:70px}
    .md-p-75px-b {padding-bottom:75px}
    .md-p-80px-b {padding-bottom:80px}
    .md-p-85px-b {padding-bottom:85px}
    .md-p-90px-b {padding-bottom:90px}
    .md-p-95px-b {padding-bottom:95px}
    .md-p-100px-b {padding-bottom:100px}

    /*Padding Left*/
    .md-p-0px-l  {padding-left:0px}
    .md-p-5px-l  {padding-left:5px}
    .md-p-10px-l {padding-left:10px}
    .md-p-15px-l {padding-left:15px}
    .md-p-20px-l {padding-left:20px}
    .md-p-25px-l {padding-left:25px}
    .md-p-30px-l {padding-left:30px}
    .md-p-35px-l {padding-left:35px}
    .md-p-40px-l {padding-left:40px}
    .md-p-45px-l {padding-left:45px}
    .md-p-50px-l {padding-left:50px}
    .md-p-55px-l {padding-left:55px}
    .md-p-60px-l {padding-left:60px}
    .md-p-65px-l {padding-left:65px}
    .md-p-70px-l {padding-left:70px}
    .md-p-75px-l {padding-left:75px}
    .md-p-80px-l {padding-left:80px}
    .md-p-85px-l {padding-left:85px}
    .md-p-90px-l {padding-left:90px}
    .md-p-95px-l {padding-left:95px}
    .md-p-100px-l {padding-left:100px}

    /*Padding right*/
    .md-p-0px-r  {padding-right:0px}
    .md-p-5px-r  {padding-right:5px}
    .md-p-10px-r {padding-right:10px}
    .md-p-15px-r {padding-right:15px}
    .md-p-20px-r {padding-right:20px}
    .md-p-25px-r {padding-right:25px}
    .md-p-30px-r {padding-right:30px}
    .md-p-35px-r {padding-right:35px}
    .md-p-40px-r {padding-right:40px}
    .md-p-45px-r {padding-right:45px}
    .md-p-50px-r {padding-right:50px}
    .md-p-55px-r {padding-right:55px}
    .md-p-60px-r {padding-right:60px}
    .md-p-65px-r {padding-right:65px}
    .md-p-70px-r {padding-right:70px}
    .md-p-75px-r {padding-right:75px}
    .md-p-80px-r {padding-right:80px}
    .md-p-85px-r {padding-right:85px}
    .md-p-90px-r {padding-right:90px}
    .md-p-95px-r {padding-right:95px}
    .md-p-100px-r {padding-right:100px}

    /*Padding All %*/
    .md-p-1 {padding:1%;}
    .md-p-1-half {padding:1.5%;}
    .md-p-2 {padding:2%;}
    .md-p-2-half {padding:2.5%;}
    .md-p-3 {padding:3%;}
    .md-p-3-half {padding:3.5%;}
    .md-p-4 {padding:4%;}
    .md-p-4-half {padding:4.5%;}
    .md-p-5 {padding:5%;}
    .md-p-5-half {padding:5.5%;}
    .md-p-6 {padding:6%;}
    .md-p-6-half {padding:6.5%;}
    .md-p-7 {padding:7%;}
    .md-p-7-half {padding:7.5%;}
    .md-p-8 {padding:8%;}
    .md-p-8-half {padding:8.5%;}
    .md-p-9 {padding:9%;}
    .md-p-9-half {padding:9.5%;}
    .md-p-10 {padding:10%;}
    .md-p-10-half {padding:10.5%;}
    .md-p-11 {padding:11%;}
    .md-p-11-half {padding:11.5%;}
    .md-p-12 {padding:12%;}
    .md-p-12-half {padding:12.5%;}
    .md-p-13 {padding:13%;}
    .md-p-13-half {padding:13.5%;}
    .md-p-14 {padding:14%;}
    .md-p-14-half {padding:14.5%;}
    .md-p-15 {padding:15%;}
    .md-p-15-half {padding:15.5%;}
    .md-p-16 {padding:16%;}
    .md-p-16-half {padding:16.5%;}
    .md-p-17 {padding:17%;}
    .md-p-17-half {padding:17.5%;}
    .md-p-18 {padding:18%;}
    .md-p-18-half {padding:18.5%;}
    .md-p-19 {padding:19%;}
    .md-p-19-half {padding:19.5%;}
    .md-p-20 {padding:20%;}
    .md-p-20-half {padding:20.5%;}
    .md-p-21 {padding:21%;}
    .md-p-21-half {padding:21.5%;}
    .md-p-22 {padding:22%;}
    .md-p-22-half {padding:22.5%;}
    .md-p-23 {padding:23%;}
    .md-p-23-half {padding:23.5%;}
    .md-p-24 {padding:24%;}
    .md-p-24-half {padding:24.5%;}
    .md-p-25 {padding:25%;}
    .md-p-25-half {padding:25.5%;}

    .md-p-1-l {padding-left:1%;}
    .md-p-1-half-l {padding-left:1.5%;}
    .md-p-2-l {padding-left:2%;}
    .md-p-2-half-l {padding-left:2.5%;}
    .md-p-3-l {padding-left:3%;}
    .md-p-3-half-l {padding-left:3.5%;}
    .md-p-4-l {padding-left:4%;}
    .md-p-4-half-l {padding-left:4.5%;}
    .md-p-5-l {padding-left:5%;}
    .md-p-5-half-l {padding-left:5.5%;}
    .md-p-6-l {padding-left:6%;}
    .md-p-6-half-l {padding-left:6.5%;}
    .md-p-7-l {padding-left:7%;}
    .md-p-7-half-l {padding-left:7.5%;}
    .md-p-8-l {padding-left:8%;}
    .md-p-8-half-l {padding-left:8.5%;}
    .md-p-9-l {padding-left:9%;}
    .md-p-9-half-l {padding-left:9.5%;}
    .md-p-10-l {padding-left:10%;}
    .md-p-10-half-l {padding-left:10.5%;}
    .md-p-11-l {padding-left:11%;}
    .md-p-11-half-l {padding-left:11.5%;}
    .md-p-12-l {padding-left:12%;}
    .md-p-12-half-l {padding-left:12.5%;}
    .md-p-13-l {padding-left:13%;}
    .md-p-13-half-l {padding-left:13.5%;}
    .md-p-14-l {padding-left:14%;}
    .md-p-14-half-l {padding-left:14.5%;}
    .md-p-15-l {padding-left:15%;}
    .md-p-15-half-l {padding-left:15.5%;}
    .md-p-16-l {padding-left:16%;}
    .md-p-16-half-l {padding-left:16.5%;}
    .md-p-17-l {padding-left:17%;}
    .md-p-17-half-l {padding-left:17.5%;}
    .md-p-18-l {padding-left:18%;}
    .md-p-18-half-l {padding-left:18.5%;}
    .md-p-19-l {padding-left:19%;}
    .md-p-19-half-l {padding-left:19.5%;}
    .md-p-20-l {padding-left:20%;}
    .md-p-20-half-l {padding-left:20.5%;}
    .md-p-21-l {padding-left:21%;}
    .md-p-21-half-l {padding-left:21.5%;}
    .md-p-22-l {padding-left:22%;}
    .md-p-22-half-l {padding-left:22.5%;}
    .md-p-23-l {padding-left:23%;}
    .md-p-23-half-l {padding-left:23.5%;}
    .md-p-24-l {padding-left:24%;}
    .md-p-24-half-l {padding-left:24.5%;}
    .md-p-25-l {padding-left:25%;}
    .md-p-25-half-l {padding-left:25.5%;}


    .md-p-1-r {padding-right: 1%;}
    .md-p-1-half-r {padding-right: 1.5%;}
    .md-p-2-r {padding-right: 2%;}
    .md-p-2-half-r {padding-right: 2.5%;}
    .md-p-3-r {padding-right: 3%;}
    .md-p-3-half-r {padding-right: 3.5%;}
    .md-p-4-r {padding-right: 4%;}
    .md-p-4-half-r {padding-right: 4.5%;}
    .md-p-5-r {padding-right: 5%;}
    .md-p-5-half-r {padding-right: 5.5%;}
    .md-p-6-r {padding-right: 6%;}
    .md-p-6-half-r {padding-right: 6.5%;}
    .md-p-7-r {padding-right: 7%;}
    .md-p-7-half-r {padding-right: 7.5%;}
    .md-p-8-r {padding-right: 8%;}
    .md-p-8-half-r {padding-right: 8.5%;}
    .md-p-9-r {padding-right: 9%;}
    .md-p-9-half-r {padding-right: 9.5%;}
    .md-p-10-r {padding-right: 10%;}
    .md-p-10-half-r {padding-right: 10.5%;}
    .md-p-11-r {padding-right: 11%;}
    .md-p-11-half-r {padding-right: 11.5%;}
    .md-p-12-r {padding-right: 12%;}
    .md-p-12-half-r {padding-right: 12.5%;}
    .md-p-13-r {padding-right: 13%;}
    .md-p-13-half-r {padding-right: 13.5%;}
    .md-p-14-r {padding-right: 14%;}
    .md-p-14-half-r {padding-right: 14.5%;}
    .md-p-15-r {padding-right: 15%;}
    .md-p-15-half-r {padding-right: 15.5%;}
    .md-p-16-r {padding-right: 16%;}
    .md-p-16-half-r {padding-right: 16.5%;}
    .md-p-17-r {padding-right: 17%;}
    .md-p-17-half-r {padding-right: 17.5%;}
    .md-p-18-r {padding-right: 18%;}
    .md-p-18-half-r {padding-right: 18.5%;}
    .md-p-19-r {padding-right: 19%;}
    .md-p-19-half-r {padding-right: 19.5%;}
    .md-p-20-r {padding-right: 20%;}
    .md-p-20-half-r {padding-right: 20.5%;}
    .md-p-21-r {padding-right: 21%;}
    .md-p-21-half-r {padding-right: 21.5%;}
    .md-p-22-r {padding-right: 22%;}
    .md-p-22-half-r {padding-right: 22.5%;}
    .md-p-23-r {padding-right: 23%;}
    .md-p-23-half-r {padding-right: 23.5%;}
    .md-p-24-r {padding-right: 24%;}
    .md-p-24-half-r {padding-right: 24.5%;}
    .md-p-25-r {padding-right: 25%;}
    .md-p-25-half-r {padding-right: 25.5%;}

    .md-p-1-t {padding-top: 1%;}
    .md-p-1-half-t {padding-top: 1.5%;}
    .md-p-2-t {padding-top: 2%;}
    .md-p-2-half-t {padding-top: 2.5%;}
    .md-p-3-t {padding-top: 3%;}
    .md-p-3-half-t {padding-top: 3.5%;}
    .md-p-4-t {padding-top: 4%;}
    .md-p-4-half-t {padding-top: 4.5%;}
    .md-p-5-t {padding-top: 5%;}
    .md-p-5-half-t {padding-top: 5.5%;}
    .md-p-6-t {padding-top: 6%;}
    .md-p-6-half-t {padding-top: 6.5%;}
    .md-p-7-t {padding-top: 7%;}
    .md-p-7-half-t {padding-top: 7.5%;}
    .md-p-8-t {padding-top: 8%;}
    .md-p-8-half-t {padding-top: 8.5%;}
    .md-p-9-t {padding-top: 9%;}
    .md-p-9-half-t {padding-top: 9.5%;}
    .md-p-10-t {padding-top: 10%;}
    .md-p-10-half-t {padding-top: 10.5%;}
    .md-p-11-t {padding-top: 11%;}
    .md-p-11-half-t {padding-top: 11.5%;}
    .md-p-12-t {padding-top: 12%;}
    .md-p-12-half-t {padding-top: 12.5%;}
    .md-p-13-t {padding-top: 13%;}
    .md-p-13-half-t {padding-top: 13.5%;}
    .md-p-14-t {padding-top: 14%;}
    .md-p-14-half-t {padding-top: 14.5%;}
    .md-p-15-t {padding-top: 15%;}
    .md-p-15-half-t {padding-top: 15.5%;}
    .md-p-16-t {padding-top: 16%;}
    .md-p-16-half-t {padding-top: 16.5%;}
    .md-p-17-t {padding-top: 17%;}
    .md-p-17-half-t {padding-top: 17.5%;}
    .md-p-18-t {padding-top: 18%;}
    .md-p-18-half-t {padding-top: 18.5%;}
    .md-p-19-t {padding-top: 19%;}
    .md-p-19-half-t {padding-top: 19.5%;}
    .md-p-20-t {padding-top: 20%;}
    .md-p-20-half-t {padding-top: 20.5%;}
    .md-p-21-t {padding-top: 21%;}
    .md-p-21-half-t {padding-top: 21.5%;}
    .md-p-22-t {padding-top: 22%;}
    .md-p-22-half-t {padding-top: 22.5%;}
    .md-p-23-t {padding-top: 23%;}
    .md-p-23-half-t {padding-top: 23.5%;}
    .md-p-24-t {padding-top: 24%;}
    .md-p-24-half-t {padding-top: 24.5%;}
    .md-p-25-t {padding-top: 25%;}
    .md-p-25-half-t {padding-top: 25.5%;}

    .md-p-1-b {padding-bottom: 1%;}
    .md-p-1-half-b {padding-bottom: 1.5%;}
    .md-p-2-b {padding-bottom: 2%;}
    .md-p-2-half-b {padding-bottom: 2.5%;}
    .md-p-3-b {padding-bottom: 3%;}
    .md-p-3-half-b {padding-bottom: 3.5%;}
    .md-p-4-b {padding-bottom: 4%;}
    .md-p-4-half-b {padding-bottom: 4.5%;}
    .md-p-5-b {padding-bottom: 5%;}
    .md-p-5-half-b {padding-bottom: 5.5%;}
    .md-p-6-b {padding-bottom: 6%;}
    .md-p-6-half-b {padding-bottom: 6.5%;}
    .md-p-7-b {padding-bottom: 7%;}
    .md-p-7-half-b {padding-bottom: 7.5%;}
    .md-p-8-b {padding-bottom: 8%;}
    .md-p-8-half-b {padding-bottom: 8.5%;}
    .md-p-9-b {padding-bottom: 9%;}
    .md-p-9-half-b {padding-bottom: 9.5%;}
    .md-p-10-b {padding-bottom: 10%;}
    .md-p-10-half-b {padding-bottom: 10.5%;}
    .md-p-11-b {padding-bottom: 11%;}
    .md-p-11-half-b {padding-bottom: 11.5%;}
    .md-p-12-b {padding-bottom: 12%;}
    .md-p-12-half-b {padding-bottom: 12.5%;}
    .md-p-13-b {padding-bottom: 13%;}
    .md-p-13-half-b {padding-bottom: 13.5%;}
    .md-p-14-b {padding-bottom: 14%;}
    .md-p-14-half-b {padding-bottom: 14.5%;}
    .md-p-15-b {padding-bottom: 15%;}
    .md-p-15-half-b {padding-bottom: 15.5%;}
    .md-p-16-b {padding-bottom: 16%;}
    .md-p-16-half-b {padding-bottom: 16.5%;}
    .md-p-17-b {padding-bottom: 17%;}
    .md-p-17-half-b {padding-bottom: 17.5%;}
    .md-p-18-b {padding-bottom: 18%;}
    .md-p-18-half-b {padding-bottom: 18.5%;}
    .md-p-19-b {padding-bottom: 19%;}
    .md-p-19-half-b {padding-bottom: 19.5%;}
    .md-p-20-b {padding-bottom: 20%;}
    .md-p-20-half-b {padding-bottom: 20.5%;}
    .md-p-21-b {padding-bottom: 21%;}
    .md-p-21-half-b {padding-bottom: 21.5%;}
    .md-p-22-b {padding-bottom: 22%;}
    .md-p-22-half-b {padding-bottom: 22.5%;}
    .md-p-23-b {padding-bottom: 23%;}
    .md-p-23-half-b {padding-bottom: 23.5%;}
    .md-p-24-b {padding-bottom: 24%;}
    .md-p-24-half-b {padding-bottom: 24.5%;}
    .md-p-25-b {padding-bottom: 25%;}
    .md-p-25-half-b {padding-bottom: 25.5%;}

}

@media (max-width: 767px){
    .sm-p-0px { padding: 0; }
    .sm-p-5px {padding: 5px}
    .sm-p-10px {padding:10px}
    .sm-p-15px {padding:15px}
    .sm-p-20px {padding:20px}
    .sm-p-25px {padding:25px}
    .sm-p-30px {padding:30px}
    .sm-p-35px {padding:35px}
    .sm-p-40px {padding:40px}
    .sm-p-45px {padding:45px}
    .sm-p-50px {padding:50px}
    .sm-p-55px {padding:55px}
    .sm-p-60px {padding:60px}
    .sm-p-65px {padding:65px}
    .sm-p-70px {padding:70px}
    .sm-p-75px {padding:75px}
    .sm-p-80px {padding:80px}
    .sm-p-85px {padding:85px}
    .sm-p-90px {padding:90px}
    .sm-p-95px {padding:95px}
    .sm-p-100px {padding:100px}

    /*Padding Left Right*/
    .sm-p-0px-lr { padding-left: 0; ; padding-right: 0;  }
    .sm-p-5px-lr { padding-left: 5px; padding-right: 5px }
    .sm-p-10px-lr {padding-left:10px; padding-right:10px }
    .sm-p-15px-lr {padding-left:15px; padding-right:15px }
    .sm-p-20px-lr {padding-left:20px; padding-right:20px }
    .sm-p-25px-lr {padding-left:25px; padding-right:25px }
    .sm-p-30px-lr {padding-left:30px; padding-right:30px }
    .sm-p-35px-lr {padding-left:35px; padding-right:35px }
    .sm-p-40px-lr {padding-left:40px; padding-right:40px }
    .sm-p-45px-lr {padding-left:45px; padding-right:45px }
    .sm-p-50px-lr {padding-left:50px; padding-right:50px }
    .sm-p-55px-lr {padding-left:55px; padding-right:55px }
    .sm-p-60px-lr {padding-left:60px; padding-right:60px }
    .sm-p-65px-lr {padding-left:65px; padding-right:65px }
    .sm-p-70px-lr {padding-left:70px; padding-right:70px }
    .sm-p-75px-lr {padding-left:75px; padding-right:75px }
    .sm-p-80px-lr {padding-left:80px; padding-right:80px }
    .sm-p-85px-lr {padding-left:85px; padding-right:85px }
    .sm-p-90px-lr {padding-left:90px; padding-right:90px }
    .sm-p-95px-lr {padding-left:95px; padding-right:95px }
    .sm-p-100px-lr {padding-left:100px; padding-right:100px }

    /*Padding Top Bottm*/
    .sm-p-0px-tb {padding-top:  0;   padding-bottom:  0;  }
    .sm-p-5px-tb {padding-top:  5px; padding-bottom:  5px }
    .sm-p-10px-tb {padding-top: 10px; padding-bottom: 10px }
    .sm-p-15px-tb {padding-top: 15px; padding-bottom: 15px }
    .sm-p-20px-tb {padding-top: 20px; padding-bottom: 20px }
    .sm-p-25px-tb {padding-top: 25px; padding-bottom: 25px }
    .sm-p-30px-tb {padding-top: 30px; padding-bottom: 30px }
    .sm-p-35px-tb {padding-top: 35px; padding-bottom: 35px }
    .sm-p-40px-tb {padding-top: 40px; padding-bottom: 40px }
    .sm-p-45px-tb {padding-top: 45px; padding-bottom: 45px }
    .sm-p-50px-tb {padding-top: 50px; padding-bottom: 50px }
    .sm-p-55px-tb {padding-top: 55px; padding-bottom: 55px }
    .sm-p-60px-tb {padding-top: 60px; padding-bottom: 60px }
    .sm-p-65px-tb {padding-top: 65px; padding-bottom: 65px }
    .sm-p-70px-tb {padding-top: 70px; padding-bottom: 70px }
    .sm-p-75px-tb {padding-top: 75px; padding-bottom: 75px }
    .sm-p-80px-tb {padding-top: 80px; padding-bottom: 80px }
    .sm-p-85px-tb {padding-top: 85px; padding-bottom: 85px }
    .sm-p-90px-tb {padding-top: 90px; padding-bottom: 90px }
    .sm-p-95px-tb {padding-top: 95px; padding-bottom: 95px }
    .sm-p-100px-tb {padding-top: 100px; padding-bottom: 100px }

    /*Padding top*/
    .sm-p-0px-t  {padding-top:0px}
    .sm-p-5px-t  {padding-top:5px}
    .sm-p-10px-t {padding-top:10px}
    .sm-p-15px-t {padding-top:15px}
    .sm-p-20px-t {padding-top:20px}
    .sm-p-25px-t {padding-top:25px}
    .sm-p-30px-t {padding-top:30px}
    .sm-p-35px-t {padding-top:35px}
    .sm-p-40px-t {padding-top:40px}
    .sm-p-45px-t {padding-top:45px}
    .sm-p-50px-t {padding-top:50px}
    .sm-p-55px-t {padding-top:55px}
    .sm-p-60px-t {padding-top:60px}
    .sm-p-65px-t {padding-top:65px}
    .sm-p-70px-t {padding-top:70px}
    .sm-p-75px-t {padding-top:75px}
    .sm-p-80px-t {padding-top:80px}
    .sm-p-85px-t {padding-top:85px}
    .sm-p-90px-t {padding-top:90px}
    .sm-p-95px-t {padding-top:95px}
    .sm-p-100px-t {padding-top:100px}

    /*Padding Bottom*/
    .sm-p-0px-b {padding-bottom:0px}
    .sm-p-5px-b {padding-bottom:5px}
    .sm-p-10px-b {padding-bottom:10px}
    .sm-p-15px-b {padding-bottom:15px}
    .sm-p-20px-b {padding-bottom:20px}
    .sm-p-25px-b {padding-bottom:25px}
    .sm-p-30px-b {padding-bottom:30px}
    .sm-p-35px-b {padding-bottom:35px}
    .sm-p-40px-b {padding-bottom:40px}
    .sm-p-45px-b {padding-bottom:45px}
    .sm-p-50px-b {padding-bottom:50px}
    .sm-p-55px-b {padding-bottom:55px}
    .sm-p-60px-b {padding-bottom:60px}
    .sm-p-65px-b {padding-bottom:65px}
    .sm-p-70px-b {padding-bottom:70px}
    .sm-p-75px-b {padding-bottom:75px}
    .sm-p-80px-b {padding-bottom:80px}
    .sm-p-85px-b {padding-bottom:85px}
    .sm-p-90px-b {padding-bottom:90px}
    .sm-p-95px-b {padding-bottom:95px}
    .sm-p-100px-b {padding-bottom:100px}

    /*Padding Left*/
    .sm-p-0px-l  {padding-left:0px}
    .sm-p-5px-l  {padding-left:5px}
    .sm-p-10px-l {padding-left:10px}
    .sm-p-15px-l {padding-left:15px}
    .sm-p-20px-l {padding-left:20px}
    .sm-p-25px-l {padding-left:25px}
    .sm-p-30px-l {padding-left:30px}
    .sm-p-35px-l {padding-left:35px}
    .sm-p-40px-l {padding-left:40px}
    .sm-p-45px-l {padding-left:45px}
    .sm-p-50px-l {padding-left:50px}
    .sm-p-55px-l {padding-left:55px}
    .sm-p-60px-l {padding-left:60px}
    .sm-p-65px-l {padding-left:65px}
    .sm-p-70px-l {padding-left:70px}
    .sm-p-75px-l {padding-left:75px}
    .sm-p-80px-l {padding-left:80px}
    .sm-p-85px-l {padding-left:85px}
    .sm-p-90px-l {padding-left:90px}
    .sm-p-95px-l {padding-left:95px}
    .sm-p-100px-l {padding-left:100px}

    /*Padding right*/
    .sm-p-0px-r  {padding-left:0px}
    .sm-p-5px-r  {padding-left:5px}
    .sm-p-10px-r {padding-left:10px}
    .sm-p-15px-r {padding-left:15px}
    .sm-p-20px-r {padding-left:20px}
    .sm-p-25px-r {padding-left:25px}
    .sm-p-30px-r {padding-left:30px}
    .sm-p-35px-r {padding-left:35px}
    .sm-p-40px-r {padding-left:40px}
    .sm-p-45px-r {padding-left:45px}
    .sm-p-50px-r {padding-left:50px}
    .sm-p-55px-r {padding-left:55px}
    .sm-p-60px-r {padding-left:60px}
    .sm-p-65px-r {padding-left:65px}
    .sm-p-70px-r {padding-left:70px}
    .sm-p-75px-r {padding-left:75px}
    .sm-p-80px-r {padding-left:80px}
    .sm-p-85px-r {padding-left:85px}
    .sm-p-90px-r {padding-left:90px}
    .sm-p-95px-r {padding-left:95px}
    .sm-p-100px-r {padding-left:100px}

    /*Padding All %*/
    .sm-p-1 {padding:1%;}
    .sm-p-1-half {padding:1.5%;}
    .sm-p-2 {padding:2%;}
    .sm-p-2-half {padding:2.5%;}
    .sm-p-3 {padding:3%;}
    .sm-p-3-half {padding:3.5%;}
    .sm-p-4 {padding:4%;}
    .sm-p-4-half {padding:4.5%;}
    .sm-p-5 {padding:5%;}
    .sm-p-5-half {padding:5.5%;}
    .sm-p-6 {padding:6%;}
    .sm-p-6-half {padding:6.5%;}
    .sm-p-7 {padding:7%;}
    .sm-p-7-half {padding:7.5%;}
    .sm-p-8 {padding:8%;}
    .sm-p-8-half {padding:8.5%;}
    .sm-p-9 {padding:9%;}
    .sm-p-9-half {padding:9.5%;}
    .sm-p-10 {padding:10%;}
    .sm-p-10-half {padding:10.5%;}
    .sm-p-11 {padding:11%;}
    .sm-p-11-half {padding:11.5%;}
    .sm-p-12 {padding:12%;}
    .sm-p-12-half {padding:12.5%;}
    .sm-p-13 {padding:13%;}
    .sm-p-13-half {padding:13.5%;}
    .sm-p-14 {padding:14%;}
    .sm-p-14-half {padding:14.5%;}
    .sm-p-15 {padding:15%;}
    .sm-p-15-half {padding:15.5%;}
    .sm-p-16 {padding:16%;}
    .sm-p-16-half {padding:16.5%;}
    .sm-p-17 {padding:17%;}
    .sm-p-17-half {padding:17.5%;}
    .sm-p-18 {padding:18%;}
    .sm-p-18-half {padding:18.5%;}
    .sm-p-19 {padding:19%;}
    .sm-p-19-half {padding:19.5%;}
    .sm-p-20 {padding:20%;}
    .sm-p-20-half {padding:20.5%;}
    .sm-p-21 {padding:21%;}
    .sm-p-21-half {padding:21.5%;}
    .sm-p-22 {padding:22%;}
    .sm-p-22-half {padding:22.5%;}
    .sm-p-23 {padding:23%;}
    .sm-p-23-half {padding:23.5%;}
    .sm-p-24 {padding:24%;}
    .sm-p-24-half {padding:24.5%;}
    .sm-p-25 {padding:25%;}
    .sm-p-25-half {padding:25.5%;}

    .sm-p-1-l {padding-left:1%;}
    .sm-p-1-half-l {padding-left:1.5%;}
    .sm-p-2-l {padding-left:2%;}
    .sm-p-2-half-l {padding-left:2.5%;}
    .sm-p-3-l {padding-left:3%;}
    .sm-p-3-half-l {padding-left:3.5%;}
    .sm-p-4-l {padding-left:4%;}
    .sm-p-4-half-l {padding-left:4.5%;}
    .sm-p-5-l {padding-left:5%;}
    .sm-p-5-half-l {padding-left:5.5%;}
    .sm-p-6-l {padding-left:6%;}
    .sm-p-6-half-l {padding-left:6.5%;}
    .sm-p-7-l {padding-left:7%;}
    .sm-p-7-half-l {padding-left:7.5%;}
    .sm-p-8-l {padding-left:8%;}
    .sm-p-8-half-l {padding-left:8.5%;}
    .sm-p-9-l {padding-left:9%;}
    .sm-p-9-half-l {padding-left:9.5%;}
    .sm-p-10-l {padding-left:10%;}
    .sm-p-10-half-l {padding-left:10.5%;}
    .sm-p-11-l {padding-left:11%;}
    .sm-p-11-half-l {padding-left:11.5%;}
    .sm-p-12-l {padding-left:12%;}
    .sm-p-12-half-l {padding-left:12.5%;}
    .sm-p-13-l {padding-left:13%;}
    .sm-p-13-half-l {padding-left:13.5%;}
    .sm-p-14-l {padding-left:14%;}
    .sm-p-14-half-l {padding-left:14.5%;}
    .sm-p-15-l {padding-left:15%;}
    .sm-p-15-half-l {padding-left:15.5%;}
    .sm-p-16-l {padding-left:16%;}
    .sm-p-16-half-l {padding-left:16.5%;}
    .sm-p-17-l {padding-left:17%;}
    .sm-p-17-half-l {padding-left:17.5%;}
    .sm-p-18-l {padding-left:18%;}
    .sm-p-18-half-l {padding-left:18.5%;}
    .sm-p-19-l {padding-left:19%;}
    .sm-p-19-half-l {padding-left:19.5%;}
    .sm-p-20-l {padding-left:20%;}
    .sm-p-20-half-l {padding-left:20.5%;}
    .sm-p-21-l {padding-left:21%;}
    .sm-p-21-half-l {padding-left:21.5%;}
    .sm-p-22-l {padding-left:22%;}
    .sm-p-22-half-l {padding-left:22.5%;}
    .sm-p-23-l {padding-left:23%;}
    .sm-p-23-half-l {padding-left:23.5%;}
    .sm-p-24-l {padding-left:24%;}
    .sm-p-24-half-l {padding-left:24.5%;}
    .sm-p-25-l {padding-left:25%;}
    .sm-p-25-half-l {padding-left:25.5%;}


    .sm-p-1-r {padding-right: 1%;}
    .sm-p-1-half-r {padding-right: 1.5%;}
    .sm-p-2-r {padding-right: 2%;}
    .sm-p-2-half-r {padding-right: 2.5%;}
    .sm-p-3-r {padding-right: 3%;}
    .sm-p-3-half-r {padding-right: 3.5%;}
    .sm-p-4-r {padding-right: 4%;}
    .sm-p-4-half-r {padding-right: 4.5%;}
    .sm-p-5-r {padding-right: 5%;}
    .sm-p-5-half-r {padding-right: 5.5%;}
    .sm-p-6-r {padding-right: 6%;}
    .sm-p-6-half-r {padding-right: 6.5%;}
    .sm-p-7-r {padding-right: 7%;}
    .sm-p-7-half-r {padding-right: 7.5%;}
    .sm-p-8-r {padding-right: 8%;}
    .sm-p-8-half-r {padding-right: 8.5%;}
    .sm-p-9-r {padding-right: 9%;}
    .sm-p-9-half-r {padding-right: 9.5%;}
    .sm-p-10-r {padding-right: 10%;}
    .sm-p-10-half-r {padding-right: 10.5%;}
    .sm-p-11-r {padding-right: 11%;}
    .sm-p-11-half-r {padding-right: 11.5%;}
    .sm-p-12-r {padding-right: 12%;}
    .sm-p-12-half-r {padding-right: 12.5%;}
    .sm-p-13-r {padding-right: 13%;}
    .sm-p-13-half-r {padding-right: 13.5%;}
    .sm-p-14-r {padding-right: 14%;}
    .sm-p-14-half-r {padding-right: 14.5%;}
    .sm-p-15-r {padding-right: 15%;}
    .sm-p-15-half-r {padding-right: 15.5%;}
    .sm-p-16-r {padding-right: 16%;}
    .sm-p-16-half-r {padding-right: 16.5%;}
    .sm-p-17-r {padding-right: 17%;}
    .sm-p-17-half-r {padding-right: 17.5%;}
    .sm-p-18-r {padding-right: 18%;}
    .sm-p-18-half-r {padding-right: 18.5%;}
    .sm-p-19-r {padding-right: 19%;}
    .sm-p-19-half-r {padding-right: 19.5%;}
    .sm-p-20-r {padding-right: 20%;}
    .sm-p-20-half-r {padding-right: 20.5%;}
    .sm-p-21-r {padding-right: 21%;}
    .sm-p-21-half-r {padding-right: 21.5%;}
    .sm-p-22-r {padding-right: 22%;}
    .sm-p-22-half-r {padding-right: 22.5%;}
    .sm-p-23-r {padding-right: 23%;}
    .sm-p-23-half-r {padding-right: 23.5%;}
    .sm-p-24-r {padding-right: 24%;}
    .sm-p-24-half-r {padding-right: 24.5%;}
    .sm-p-25-r {padding-right: 25%;}
    .sm-p-25-half-r {padding-right: 25.5%;}

    .sm-p-1-t {padding-top: 1%;}
    .sm-p-1-half-t {padding-top: 1.5%;}
    .sm-p-2-t {padding-top: 2%;}
    .sm-p-2-half-t {padding-top: 2.5%;}
    .sm-p-3-t {padding-top: 3%;}
    .sm-p-3-half-t {padding-top: 3.5%;}
    .sm-p-4-t {padding-top: 4%;}
    .sm-p-4-half-t {padding-top: 4.5%;}
    .sm-p-5-t {padding-top: 5%;}
    .sm-p-5-half-t {padding-top: 5.5%;}
    .sm-p-6-t {padding-top: 6%;}
    .sm-p-6-half-t {padding-top: 6.5%;}
    .sm-p-7-t {padding-top: 7%;}
    .sm-p-7-half-t {padding-top: 7.5%;}
    .sm-p-8-t {padding-top: 8%;}
    .sm-p-8-half-t {padding-top: 8.5%;}
    .sm-p-9-t {padding-top: 9%;}
    .sm-p-9-half-t {padding-top: 9.5%;}
    .sm-p-10-t {padding-top: 10%;}
    .sm-p-10-half-t {padding-top: 10.5%;}
    .sm-p-11-t {padding-top: 11%;}
    .sm-p-11-half-t {padding-top: 11.5%;}
    .sm-p-12-t {padding-top: 12%;}
    .sm-p-12-half-t {padding-top: 12.5%;}
    .sm-p-13-t {padding-top: 13%;}
    .sm-p-13-half-t {padding-top: 13.5%;}
    .sm-p-14-t {padding-top: 14%;}
    .sm-p-14-half-t {padding-top: 14.5%;}
    .sm-p-15-t {padding-top: 15%;}
    .sm-p-15-half-t {padding-top: 15.5%;}
    .sm-p-16-t {padding-top: 16%;}
    .sm-p-16-half-t {padding-top: 16.5%;}
    .sm-p-17-t {padding-top: 17%;}
    .sm-p-17-half-t {padding-top: 17.5%;}
    .sm-p-18-t {padding-top: 18%;}
    .sm-p-18-half-t {padding-top: 18.5%;}
    .sm-p-19-t {padding-top: 19%;}
    .sm-p-19-half-t {padding-top: 19.5%;}
    .sm-p-20-t {padding-top: 20%;}
    .sm-p-20-half-t {padding-top: 20.5%;}
    .sm-p-21-t {padding-top: 21%;}
    .sm-p-21-half-t {padding-top: 21.5%;}
    .sm-p-22-t {padding-top: 22%;}
    .sm-p-22-half-t {padding-top: 22.5%;}
    .sm-p-23-t {padding-top: 23%;}
    .sm-p-23-half-t {padding-top: 23.5%;}
    .sm-p-24-t {padding-top: 24%;}
    .sm-p-24-half-t {padding-top: 24.5%;}
    .sm-p-25-t {padding-top: 25%;}
    .sm-p-25-half-t {padding-top: 25.5%;}

    .sm-p-1-b {padding-bottom: 1%;}
    .sm-p-1-half-b {padding-bottom: 1.5%;}
    .sm-p-2-b {padding-bottom: 2%;}
    .sm-p-2-half-b {padding-bottom: 2.5%;}
    .sm-p-3-b {padding-bottom: 3%;}
    .sm-p-3-half-b {padding-bottom: 3.5%;}
    .sm-p-4-b {padding-bottom: 4%;}
    .sm-p-4-half-b {padding-bottom: 4.5%;}
    .sm-p-5-b {padding-bottom: 5%;}
    .sm-p-5-half-b {padding-bottom: 5.5%;}
    .sm-p-6-b {padding-bottom: 6%;}
    .sm-p-6-half-b {padding-bottom: 6.5%;}
    .sm-p-7-b {padding-bottom: 7%;}
    .sm-p-7-half-b {padding-bottom: 7.5%;}
    .sm-p-8-b {padding-bottom: 8%;}
    .sm-p-8-half-b {padding-bottom: 8.5%;}
    .sm-p-9-b {padding-bottom: 9%;}
    .sm-p-9-half-b {padding-bottom: 9.5%;}
    .sm-p-10-b {padding-bottom: 10%;}
    .sm-p-10-half-b {padding-bottom: 10.5%;}
    .sm-p-11-b {padding-bottom: 11%;}
    .sm-p-11-half-b {padding-bottom: 11.5%;}
    .sm-p-12-b {padding-bottom: 12%;}
    .sm-p-12-half-b {padding-bottom: 12.5%;}
    .sm-p-13-b {padding-bottom: 13%;}
    .sm-p-13-half-b {padding-bottom: 13.5%;}
    .sm-p-14-b {padding-bottom: 14%;}
    .sm-p-14-half-b {padding-bottom: 14.5%;}
    .sm-p-15-b {padding-bottom: 15%;}
    .sm-p-15-half-b {padding-bottom: 15.5%;}
    .sm-p-16-b {padding-bottom: 16%;}
    .sm-p-16-half-b {padding-bottom: 16.5%;}
    .sm-p-17-b {padding-bottom: 17%;}
    .sm-p-17-half-b {padding-bottom: 17.5%;}
    .sm-p-18-b {padding-bottom: 18%;}
    .sm-p-18-half-b {padding-bottom: 18.5%;}
    .sm-p-19-b {padding-bottom: 19%;}
    .sm-p-19-half-b {padding-bottom: 19.5%;}
    .sm-p-20-b {padding-bottom: 20%;}
    .sm-p-20-half-b {padding-bottom: 20.5%;}
    .sm-p-21-b {padding-bottom: 21%;}
    .sm-p-21-half-b {padding-bottom: 21.5%;}
    .sm-p-22-b {padding-bottom: 22%;}
    .sm-p-22-half-b {padding-bottom: 22.5%;}
    .sm-p-23-b {padding-bottom: 23%;}
    .sm-p-23-half-b {padding-bottom: 23.5%;}
    .sm-p-24-b {padding-bottom: 24%;}
    .sm-p-24-half-b {padding-bottom: 24.5%;}
    .sm-p-25-b {padding-bottom: 25%;}
    .sm-p-25-half-b {padding-bottom: 25.5%;}
}


html{overflow-x:hidden; }
body{font-family: 'Roboto', sans-serif; color: #000000; font-size: 15px; overflow-x:hidden;}
.home-main{padding-top:100px; width: 100%;float: left;}

/***Common Start Here***/
.home-main section{ width: 100%; padding:50px 0; float: left; }
.home-main p{ margin-bottom: 22px; line-height: 25px;}
.home-main .cmn-padding{ padding:0 20px }
.home-main *{outline: none !important; box-sizing: border-box; padding: 0px; margin: 0px; list-style: none; text-decoration: none; font-weight: normal;}
.home-main img{max-width: 100%;}
.pull-left{float: left;}
.pull-right{float:right;}
.home-main a{transition: all .5s ease 0s; -webkit-transition: all .5s ease 0s; color: #000;}
.home-main a:hover{ text-decoration: none; }
.bdr-btn{ color: #000; font-size: 17px; display: inline-block; border: #f6bf1d 2px solid; padding: 0px 15px; text-align: center; min-width: 156px; height: 44px; line-height: 40px;}
.bdr-btn:hover{color: #fff; background:#f6bf1d }
.home-main h2{font-size: 40px; color: #48b1e3; margin-bottom: 20px;font-family: 'Ubuntu', sans-serif;}
.home-main h3{font-size: 30px; color: #48b1e3;  margin-bottom: 15px;font-family: 'Ubuntu', sans-serif;}
.home-main h4{font-size: 20px; color: #000; margin-bottom: 15px;font-family: 'Roboto', sans-serif;}
.home-main strong{font-weight: 700}
.home-main .this-overview-scn{padding: 25px 0; display: table;}
.this-overview-dtl{  display: table-cell;
    text-align: center;
    vertical-align: middle;}
.home-main .txt-orange{color: #f1662a}
.greylight-bg{background: #f8f9fc}
.home-main .container{max-width: 1170px; margin:auto; width: 100%; display: block;}
@-webkit-keyframes fadeIn{0%{opacity:0}40%{opacity:0.4}80%{opacity:0.8}100%{opacity:1}}
@keyframes fadeIn{0%{opacity:0}50%{opacity:0.5}80%{opacity:0.8}100%{opacity:1}}
.fadeIn{-webkit-animation-name:fadeIn;animation-name:fadeIn}
/***Common End Here***/
/***Header Start Here***/
.home-main header{ position:fixed; top:0px; left:0px; background: #f8f9fc;width: 100%; z-index:999; float: left; -webkit-transition: all .3s ease; -o-transition: ease all .3s; transition: all .3s ease;}
.home-main .hdrFixed{    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.1); box-shadow: 0 6px 12px rgba(0,0,0,.1);}
.logo{margin: 0px;}
.logo a{margin: 24px 0; float: left;}
.logo a img{ display: block; }
.mobile-togglemenu{ float: right; width: 30px; cursor: pointer; border-top: #f16022 3px solid; margin-top: 28px; display: none;}
.mobile-togglemenu i{ width: 100%; float:left; clear: both; margin-top: 4px; height: 3px; background-color: #f16022 }
.menu-main li{ float: left; margin-left: 18px;}
.menu-main li a{ line-height: normal; padding:64px 7px 18px 7px;  color:#000; position: relative; float: left;}
.menu-main li a:after{content: ''; position: absolute;left: 0px; right: 0px; bottom: 0px; margin: auto; width: 0px; height: 1px; background: #f16022; transition: all .5s ease 0s; -webkit-transition: all .5s ease 0s;}
.menu-main nav{ float:left;}
.menu-main li a:hover, .menu-main li a.active {color: #f16022}
.menu-main li a:hover:after, .menu-main li a.active:after{width: 100%;}
.home-main .login{ line-height: normal; margin-left:18px; float:left; background:#f16022; color: #fff; padding: 25px 30px 20px 30px}
.home-main .login i{ background: url('/public/images/user-icon.png') no-repeat; width: 30px; height: 30px; display: table; margin: 0 auto 7px auto; }
.home-main .login:hover{font-weight: normal; background:#e5571b }
.menucmn-link{float: left; line-height: normal; position: relative; padding: 64px 7px 18px 7px; margin-left: 18px;}
.menucmn-link:after{content: ''; position: absolute;left: 0px; right: 0px; bottom: 0px; margin: auto; width: 0px; height: 1px; background: #f16022; transition: all .5s ease 0s; -webkit-transition: all .5s ease 0s;}
.menucmn-link:hover, .menucmn-link.active{color:#f16022}
.menucmn-link:hover:after, .menucmn-link.active:after{width: 100%;}

/***Header End Here***/
/***Midddle Start Here***/
.middle-scn-main{ float:left; width:100%;}
.this-cont{ text-align: left; display: inline-block;vertical-align: middle; width: 670px; }
.this-overview-img{display: inline-block; vertical-align: middle; width: 470px;  margin: auto;}
.home-main .this-overscn-scn{ overflow:hidden; padding:60px 0 0 0; }
.home-main .stock-tech-scn, .home-main .help-cstmr-scn, .home-main .customer-happy-scn, .home-main .get-start-scn{ overflow:hidden; text-align: center;}
.stock-tech-cont{max-width: 680px; margin: auto; width: 100%;}
.help-cstmr-cont{max-width: 940px; margin: auto; width: 100%;}
.help-cstmr-services{ margin-top: 25px; width: 100%; float:left;}
.help-services-item{float: left; width: 33.3%; float: left;}
.help-services-item p{ line-height: 22px; font-size: 14px; }
.srv-item-dtl{ max-width: 250px; width: 100%; margin: auto; }
.help-services-item h4{ margin-bottom: 10px; margin-top: -10px }
.help-services-item .bdr-btn{border-color:#48b1e3; font-size: 14px; min-width: 106px; height: 36px; line-height: 32px;}
.help-services-item .bdr-btn:hover{ background: #48b1e3 }
.cstmr-happy-cont{ max-width: 570px; width: 100%; margin: auto; }
.ourservices-main{width: 100%; margin-top: 15px;  height: 80px; vertical-align: middle; display: table;}
.ourservices-item{ position: relative; width: 20%; padding-top: 5px; min-height: 52px; display: table-cell;vertical-align: middle; text-align: center;}
.ourservices-item:before { content: '';
    height: 50px;
    width: 1px;
    background: #cccccc;
    float: left;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
}
.ourservices-main .ourservices-item:first-child:before{ content:normal; }
.get-start-center{ max-width: 560px; width: 100%; margin: auto; }
.addemail-id-form{ display: table; margin: auto; }
.addemail-id-form .input-addemail{ border-radius:40px 0px 0px 40px; padding: 0px 18px; width: 280px; float: left; color: #999; font-size: 14px; background: #fff; height: 40px; line-height: 40px; border: none; }
.addemail-btn{ background-color: #f16022; background-image: url('/public/images/add-email-icon.png'); background-repeat: no-repeat;background-position: 12px 10px; border: none; cursor: pointer; border-radius:0px 40px 40px 0px;width: 52px; height: 40px; float: left; }
.addemail-btn:hover{background-color: #e5571b}
/***Team End Here***/
/***Midddle End Here***/

/***Footer End Here***/
.home-main footer{position: static; width: 100%; float: left; background: none;padding:0px; margin: 0px; text-align: none}
.home-main .ftr-top-scn{padding: 40px 0;}
.ftr-logo-address{
    color: #000000; max-width: 450px; width: 100%; }
.ftr-logo-address p{ width: 100%; clear: both; float: left; }
.ftr-logo{ margin-bottom: 10px; float: left; }
.home-main .ftr-address{float: left; width: 100%; clear: both;}
.ftr-phone-emails{ clear: both; float: left; font-weight: 600; color: #000;}
.ftr-phone-emails a{font-weight: 600; color: #000;}
.ftr-phone-emails a:hover{ color: #f16022 }
.callusftr{ margin-bottom: 10px; display: block;font-weight: 600; }
.ftr-links-main{ display: table; width: 100%;}
.ftr-top-scn h3{ text-transform: uppercase; font-size: 17px; color:#48b1e3; font-weight: 700; margin-bottom: 10px }
.ftr-links-main .ftrlink-col{ display: table; margin: auto; border-bottom: #f5f5f5 1px solid; padding-bottom: 10px; }
.ftr-top-scn .ftr-links-main h3{ margin-bottom: 5px; }
.ftr-links-main ul li{ font-size: 13px; padding: 0px 18px; float: left; }
.ftr-links-main ul li a:hover{color: #f16022}
.ftr-socialmedia a{ background:url('/public/images/ftr-socialmedia.png') no-repeat; margin-left: 13px; float: left; width: 30px; height: 30px;}
.ftr-socialmedia a:first-child{margin-left: 0px}
.ftr-socialmedia a:hover{opacity: 0.75;}
.ftr-socialmedia a.tw-icon{ background-position: -43px 0px; }
.ftr-socialmedia a.lnk-icon{ background-position: -87px 0px; }
.ftr-socialmedia a.glp-icon{ background-position: -130px 0px; }
.home-main .social-copy-scn{
    color:#000000; background: #f8f9fc;padding: 20px 0 10px 0 }
.copy-txt{ margin-top: 6px; }
.ftr-email{
    color: #000;
    display: table;
    margin: 20px auto 0 auto;
}
.ftr-email a{color: #f16022}
.ftr-email a:hover{color: #ec5a1b}
.ftr-email i {
    font-size: 18px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
}
/*.copy-txt strong{text-transform: uppercase;}*/
/***Footer End Here***/

/***Plan Start Here***/
.switcher{line-height: normal}
.plan-middle{ text-align: center; }
.plan-middle .switcher-title {
    color: #0a293f;
    font-size: 16px;
    letter-spacing: .5px; vertical-align: middle; display: inline-block;
}
.switcher-body { vertical-align:middle;
    background-color: #48b1e3;
    border-radius: 22px;
    cursor: pointer;
    width: 45px;
    height: 22px;
    padding: 3px 2px; text-align:left;
    margin: 0 8px;
    position: relative; display: inline-block;
}
.switcher-input {
    display: none;
}
.switcher-pointer {
    float: left;
    background-color: #fff;
    border-radius: 100%;
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    -ms-transform: translateX(5%);
    transform: translateX(5%);
    transition: transform .35s ease;
}
.switcher-input:checked+.switcher-pointer {
    -ms-transform: translateX(165%);
    transform: translateX(165%);
}
.plan-benefits-values{line-height: normal}
.pricingplan-list{text-align: left; width: 100%; margin-top: 40px;}
.pricingplan-item{position: relative; max-width: 23.5%; margin-left: 2%; padding: 20px; background:#f8f9fc; float: left; width: 100%; }
.pricingplan-item:nth-child(4n+1){ margin-left: 0; }
.banner--rotate-left {
    width: 4.25rem;
    height: 4.25rem;
    border-radius: 100%;
    background-color: #f16022;
    position: absolute;
    right: .875rem;
    top: -2.5rem;
    box-shadow: -1.4px 2.7px 0 0 rgba(0,31,145,.16);
}
.banner__text:last-child {
    font-weight: 700;
    font-size: 10px;

    letter-spacing: .5px;
    line-height: 1.25;
    position: relative;
}

.banner__inner {
    /* -ms-transform: rotate(-10deg);
     transform: rotate(-10deg);*/
    padding: .6875rem 0 .9375rem;
    color: #fff;
    text-align: center;
    line-height: 7px;
    font-weight: 300; text-transform: uppercase;
}
.banner__text:last-child:after {
    content: "";
    width: 1.375rem;
    height: 1.25rem;
    background: url('/public/images/link-icon.png') 50% 50% no-repeat;
    position: absolute;
    background-size: contain;
    left: 50%;
    margin-left: -.9500rem;
    bottom: -1.5625rem;
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
}
.plan-benefits-list{ min-height: 220px; margin-bottom: 15px; width: 100%; float: left; }
.plan-benefits-list li{ line-height: normal; clear: both; width: 100%; margin: 7px 0; font-size: 12px; position: relative; padding-left: 12px}
.plan-benefits-list li:before{content: ''; position: absolute; left: 0px; top:5px; width: 4px; height: 4px; border-radius: 100%; background-color:#f18150}
.plan-benefits-icon{ min-height: 155px;  max-width: 150px; margin:auto;margin-bottom: 15px; }
.pricingplan-item .bdr-btn{ text-align: center; display: table; margin: auto; font-size: 14px; min-width: 120px; height: 40px; line-height: 36px; font-weight: 600; text-transform: uppercase; }
.plan-benefits-values-sign{ font-size: 20px; display: inline-block; vertical-align: middle; }
.plan-benefits-values-cost{ font-size: 30px; display: inline-block; color: #000; font-weight: 500; vertical-align: middle; }
#agency-mob{ display:none; }
.hide4yearly, .plan-benefits-values-yearval{ font-size: 10px; display: block; margin-bottom: 5px; margin-top: 5px; }
.plan-benefits-values-yearval em{ font-style: normal; }
.txt-bold{font-weight: 700;}
.plan-month-txt{ font-size: 16px; font-weight: 500; }
.plan-benefits-item{ background:#f9f5eb }
.plan-benefits-item .btnprice-btn{ color: #fff; background:#f6bf1d }
.plan-proplus-icon .icon{ top: 20px; position: relative; }
.pricingplan-item:first-child .plan-benefits-list{ min-height: 247px; }
.letcompare-prt{ width: 100%; float: left; padding: 60px 0; }
.plan-tablemain table { line-height: normal}
.home-main .table tr th span{font-size: 12px; padding-top: 5px; color: #999; text-align: center; display: block; font-weight: normal;}
.home-main .table tr th, .table tr td{ padding: 0px; border: none; }
.home-main .table tr th{ padding:12px; font-size: 18px; color: #3e3e3e; font-weight: 600; }
.home-main .table tbody{ box-shadow: #ccc 0px 0px 5px 0px; -moz-box-shadow: #ccc 0px 0px 5px 0px; -webkit-box-shadow: #ccc 0px 0px 5px 0px; }
.home-main .table tr th:first-child{ text-align: left; vertical-align: bottom; }
.home-main .table tr td{ padding: 12px; font-size:14px;  border-bottom: #f0f0f0 1px solid;border-left: #f0f0f0 1px solid;}
.home-main .table tr td:first-child{ border-left: none; text-align: left; }
.plan-checkicon{ width: 16px; height: 14px; background: url('/public/images/checked-icon.png') no-repeat; background-size: 100% auto; display:inline-block; vertical-align: middle; margin:auto; }
.home-main .table tr .table-orange{color:#f16022;}
.home-main .table tbody tr:nth-child(even) td{ background:#f9f9f9 }
.table-orange .plan-checkicon{background-image: url('/public/images/checked-orange-icon.png');}
.pricingplan-item h3{display: inline-block; vertical-align: middle;}
.pricingplan-item h3 .badge--discount{vertical-align: middle; font-size: 11px; border-radius: 10px; color: #fff; background:#bfbfbf; padding:3px 8px 4px 8px; }
.adv-animation{position: relative;}
.cmngsoon-txt{ transform: rotate(20deg); margin: -15px 0px 0px 10px; position: absolute; width: 40px; text-align: center; border-radius: 100%; height: 40px; font-size: 9px; padding-top:9px;  background:#f16022; color: #fff;  overflow: hidden; display: inline-block; vertical-align: middle; }
.pricingplan-item.disabled{ pointer-events: none; }
.banner--agency{display: none;}
.pricingplan-item.disabled #agency-desk{ display: none; }
.pricingplan-item.disabled #agency-mob{ display: block; }
.pricingplan-item.disabled .banner--agency{ display: block; background:#929292; color: #fff; text-align: center;left: 30px;top: 94px;}
.pricingplan-item.disabled .banner-text{display: inline-block; padding-top: 14px;-ms-transform: rotate(-10deg);
    transform: rotate(-10deg);}
.pricingplan-item.disabled h3, .pricingplan-item.disabled .plan-benefits-values, .disabled .plan-benefits-values-yearval, .disabled .plan-benefits-list, .disabled .btnprice-btn { opacity: 0.4 }
#main_1 .st0{opacity:0.54;} #main_1 .st1{fill:#fbd2c0;} #main_1 .st2{fill:#f1662a;} #main_1 .st3{fill:#fbd2c0;} #main_1 .st4{fill:#FFFFFF;}
#main_2 .cls-1{opacity:0.54;}#main_2 .cls-2{fill:#fbd2c0;}#main_2 .cls-3{fill:#f1662a;}#main_2 .cls-4{fill:#fff;}#main_2 .cls-5{fill:#f16022;}#main_2 .cls-6{fill:#e0794c;}
#main_3 .cls-1{fill:#f16022;}#main_3 .cls-2,.cls-3,.cls-4{fill:#e06e3d;}#main_3 .cls-3{opacity:0.57;}#main_3 .cls-4{opacity:0.25;}#main_3 .cls-5{fill:#e0794c;}#main_3 .cls-6{fill:#fff;}#main_3 .cls-7{fill:#f1662a;}
#main_4 .cls-1{fill:#fff;}#main_4 .cls-2{fill:#f1662a;}#main_4 .cls-3{fill:#8c8ca8;opacity:0.2;}#main_4 .cls-4{fill:#f16022;}#main_4 .cls-5{fill:#e0794c;}#main_4 .cls-6{fill:#e06e3d;opacity:0.54;}
#agency_mob .cls-1{fill:#fff;}#agency_mob .cls-2{fill:#a2a6b4;}#agency_mob .cls-3{fill:#8c8ca8;opacity:0.2;}#agency_mob .cls-4{fill:#93939e;}#agency_mob .cls-5{fill:#c9d3e2;}#agency_mob .cls-6{fill:#d3d6dd;}#agency_mob .cls-6,#agency_mob .cls-8{opacity:0.54;}#agency_mob .cls-7{fill:#d3d7e0;}#agency_mob .cls-8{fill:#c5cadb;}
/***Plan End Here***/

/***Slider Start Here***/
.ourservices-item{ position: relative; padding-top: 5px; min-height: 52px; display: table-cell;vertical-align: middle; text-align: center;}
.owl-carousel .owl-item .ourservices-item img{width:auto; margin:auto;}
.customer-happy-scn .container{ overflow:hidden;}
/***Slider End Here***/
 .login-box input:-webkit-autofill { -webkit-box-shadow: inset 0 0 0px 9999px white !important;}

 .header-container {
    background-color: #9E588F;
    height: auto;
    z-index: 0;
    position: relative;
}

.header-container h2 {
    color: #fff;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    padding-bottom: 94px;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.header-top {
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    padding-top: 46px;
    margin: 0;
}

.sub-text{
    color: #FFF;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.text {
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-top: 0;
    /* 40px */
}

.main-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    margin: -56px auto 0;
    padding: 30px;
    position: relative;
    z-index: 1;
    max-width: 1170px;
}

.main-card h2 {
    color: #000;
    font-size: 25px;
}

.main-card p {
    margin-bottom: 16px;
    font-size: 16px;
}


.main-card ul li {
    list-style: disc;
    margin-bottom: 10px;
}

.main-card ul {
    padding-left: 28px;
}

.menu-inner {
    display: flex;
}


@media(max-width: 767px){
	.extra-header h1.pull-left.logo {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.extra-header .pull-right.menu-main{
		display: none;
	}
	.header-container h2 {
    	font-size: 28px;
	}
}
