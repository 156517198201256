@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
/*------------------------------------------------
	Global CSS
------------------------------------------------*/
.box-shadow {
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.15);
}

.btn:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.31);
}
.btn.btn-dark {
  background: #000;
  color: #fff;
}
.btn.btn-dark:hover {
  background: #222;
}

.btn.btn-light {
  background: #eeee;
}

.btn-set {
  padding-top: 25px;
}
.btn-set.text-left .btn {
  margin-right: 15px;
}
.btn-set.text-right {
  margin-left: 15px;
}
.btn-set.text-center {
  margin: 0 8px;
}

.form-title {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 5px;
  margin: 25px 0 15px;
  color: black;
}
.form-title:after {
  width: 50px;
  height: 2px;
  content: "";
  float: left;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #666;
}
.form-title.first {
  margin-top: 0;
}

.grey-bg {
  background: #eee;
}

/*------------------------------------------------
	Form Style
------------------------------------------------*/

.input-field {
  position: relative;
  width: 100%;
}

.es-floating-label label {
  position: absolute;
  pointer-events: none;
  color: #7f868d;
  left: 0px;
  top: 5px;
  bottom: 0px;
  transition: 0.2s ease all;
  font-size: 14px;
}

.es-floating-label textarea:not(:placeholder-shown) ~ label,
.es-floating-label textarea:focus ~ label,
.es-floating-label input:not(:placeholder-shown) ~ label,
.es-floating-label input:focus ~ label,
.es-floating-label label.focused {
  top: -13px;
  font-size: 10px;
  background-color: inherit;
  color: #7f868d;
}

.es-floating-label label span {
  color: red;
}

.input-field .form-control {
  border: none;
  outline: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  width: 100%;
}
.input-field-group.input-field .form-control {
  width: auto;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

.input-field select {
  border: none;
  outline: none;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  width: 100%;
  font-size: 14px;
}

.input-field .input-focus-effect {
  width: 0 !important;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: ease all 0.3s;
  height: 2px;
  background: #26a69a;
}

.input-field input:not([type]),
.input-field input[type="text"]:not(.browser-default),
.input-field input[type="password"]:not(.browser-default),
.input-field input[type="email"]:not(.browser-default),
.input-field input[type="url"]:not(.browser-default),
.input-field input[type="time"]:not(.browser-default),
.input-field input[type="date"]:not(.browser-default),
.input-field input[type="datetime"]:not(.browser-default),
.input-field input[type="datetime-local"]:not(.browser-default),
.input-field input[type="tel"]:not(.browser-default),
.input-field input[type="number"]:not(.browser-default),
.input-field input[type="search"]:not(.browser-default),
.input-field input[type="phone"]:not(.browser-default) {
  height: 40px;
}

.input-field input:not([type]):focus:not([readonly]) + span.input-focus-effect,
.input-field
  input[type="text"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="password"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="email"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="url"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="time"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="date"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="datetime"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="tel"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="number"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field
  input[type="search"]:not(.browser-default):focus:not([readonly])
  + span.input-focus-effect,
.input-field textarea:focus:not([readonly]) + span.input-focus-effect {
  width: 100% !important;
}

.input-field-group input[type="number"]:not(.browser-default):focus:not([readonly]) ~ span.input-focus-effect {
  width: 100% !important;
  z-index: 3;
}

.input-field input[readonly] {
  /*padding-left: 10px;*/
  padding-right: 10px;
}

.input-field select:focus:not([readonly]) + span.input-focus-effect {
  width: 100% !important;
}

/*/End/*/

/*------------------------------------------------
	User Profile
------------------------------------------------*/
.user-profile-box {
  margin: 50px 0;
  padding: 40px;
  width: 100%;
  display: inline-block;
  background: #fff;
  border-radius: 5px;
}
.user-img {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  height: 100px;
  position: relative;
  margin-bottom: 40px;
}
.user-img .custom-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
}

.user-img .custom-file .custom-file-input {
  height: 100%;
}

.user-img span {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
}
.user-img .icon {
  position: absolute;
  top: 0px;
  background: #48dad0;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  right: -7px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.18);
}
