a, img {
    border: none;
    text-decoration: none;
    color: #df0024;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
}

a:hover {
    color: #f16022;
    outline: none;
    text-decoration: none;
}

a:active {
    outline: none;
    outline: none;
    text-decoration: none;
}

a:focus {
    -moz-outline-style: none;
    outline: none;
}

a:visited {
    -moz-outline-style: none;
    outline: none;
    text-decoration: none !important;
}

p {
    margin: 0 0 17px 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.top-header {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    float: left;
    top: 0px;
    z-index: 1;
}
.brand-logo {
    width: 200px;
    float: left;
    line-height: 60px;
}

.brand-logo img {
    max-width: 100%;
    max-height: 50px;
    vertical-align: middle;
}

.container-main {
    max-width: 1400px;
    margin: 0 auto;
    float: none;
    width: 100%;
    padding: 0 15px !important;
}
.login-signup-option {
    float: right;
}

.login-signup-option li {
    float: left;
    margin: 0;
}

.login-signup-option li:after {
    content: "/";
    display: inline-block;
    vertical-align: middle;
    color: #fafdfd;
}

.login-signup-option li:last-child:after {
    display: none;
}

.login-signup-option a, .login-signup-option button {
    color: #fff;
    font-size: 13px;
    display: inline-block;
    line-height: 26px;
    padding: 0 5px 6px;
}

.login-signup-option a:hover, .login-signup-option button:hover, .login-signup-option .active a {
    color: #f16022
}

.login-signup-option > li > a .notification-bell-default{ font-size: 10px;position: relative;top: -1px;}
.login-signup-option > li > a .notification-bell-red{ color: red;font-size: 10px;position: relative;top: -1px;}

.headersection {
    position: fixed;
    top: 30px;
    left: 0;
    width: 100%;
    background: #fafdfd;
    z-index: 99;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.10);
    transition: all 0.3s ease;
}
.navigation-block {
    width: calc(100% - 212px);
    float: right;
}

.navigation-block > ul {
    width: auto;
    float: right;
}

.navbar-menu > li {
    float: left;
}

.navbar-menu > li:last-child a {
    padding-right: 5px;
}

.navbar-menu > li > a {
    font-size: 13px;
    line-height: 60px;
    color: #253740;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 20px 0;
    position: relative;
    font-weight: 600;
    border-bottom: 1px solid transparent;
}
.navbar-menu > li > a:hover, .navbar-menu > li.active > a{
    border-bottom: 1px solid #f16022;
    color: #f16022;
}
footer {
    color: #fff;
    margin-top: 20px;
    background: #f0f1f5;
    min-height: 45px;
    padding: 30px;
    text-align: left;
    bottom: 0px;
    position: fixed;
    z-index: 99;
}

.footer-section {
    background: #f2f2f2;
    padding: 20px 0;
    width: 100%;
    float: left;
}

.footer-section p, .footer-section p a {
    font-size: 12px;
    color: #555;
    line-height: normal;
    margin: 0;
}

.inner-main {
    margin-bottom: 70px;
    margin-top: 120px;
    min-height: 70vh;
}

.header-fixed {
    top: 0;
}
.input-view {
    white-space: normal;
}
.input-view label {
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #333;
}

.prem-amt label {
    font-weight: normal;
}
.dropzone-container {
    width: 100%;
    height: 100px;
    border-width: 1px;
    border-color: rgb(102, 102, 102);
    border-style: solid;
    border-radius: 5px;
}
.dropzone-active-container {
    width: 100%;
    height: 100px;
    border-width: 2px;
    border-color: rgb(102, 204, 102);
    border-style: solid;
    border-radius: 5px;
    background-color: rgb(238, 238, 238);
}
.dropzone-reject-container {
    width: 100%;
    height: 100px;
    border-width: 2px;
    border-color: red;
    border-style: solid;
    border-radius: 5px;
    background-color: rgb(238, 238, 238);
}

/********************/
/* Sign Up & Login */
/********************/
.startup-wrapper {
	background: #dcdcdc;
}
.startup-wrapper:after {
	content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: url(/public/images/banner-1.png) no-repeat scroll left top;
    height: 100%;
}

.invalid-top {
	background: #ffb4b4;
    padding: 10px;
    margin-bottom: 30px;
    border-left: 5px solid #ef8080;
}
.invalid-top ul {
	margin: 0;
	padding: 0;
}
.invalid-top li {
	list-style-type: none;
    color: #a20b0b;
    margin: 3px 0;
    font-size: 13px;
    text-shadow: 1px 1px #ffc8c8;
    font-weight: 600;
}

.rb-full-height {
	min-height: 100vh;
}
.signup-logo {
	text-align: center;
	padding-bottom: 40px;
}
.signup-box .login-with {
	text-align: center;
	display: none;
}
.signup-box .login-with label {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-size: 15px;
	font-weight: 600;
	color: #111;
	margin: 0;
}
.signup-box .login-with ul {
	margin: 0;
	padding: 0;
	text-align: center;
	display: inline-block;
	vertical-align: middle;
}
.signup-box .login-with li {
	display: inline-block;
	vertical-align: middle;
	margin: 0 10px;
}
.signup-box .login-with li a {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: inline-block;
	color: #fff;
	line-height: 50px;
	font-size: 20px;
}
.signup-box .login-with li.google-link a  {
	background: #4385f5;
}

.signup-box .login-with li.fb-link a  {
	background:#3b5997 ;
}

.signup-box .login-with li.in-link a  {
	background:#007cba ;
}

.signup-box {
	margin: 40px auto;
	width: 700px;
	max-width: 100%;
	padding: 40px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px 2px;
    position: relative;
    z-index: 1;
}
.signup-box .form-action {
	margin-top: 25px;
}

.signup-box .form-action .back-link {
	float: right;
	margin-top: 12px;
}

.login-box {
	margin: 40px auto;
    width: 400px;
    max-width: 100%;
    padding: 40px 40px 70px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px 2px;
    position: relative;
    z-index: 1;
}
.login-box .login-logo {
	text-align: center;
	margin-bottom: 30px;
}

.login-box .custom-control-label {
	font-size: 12px;
}

.login-box .form-action .forgot-link {
	float: right;
    color: #000;
    text-decoration: none !important;
    font-size: 12px;
    margin-top: 12px;
}

.login-box .signup-link {
	position: absolute;
    bottom: -11px;
    left: 30px;
    right: 30px;
    text-align: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 16px 2px;
    border-radius: 30px;
    padding: 6px 0;
    font-size: 14px;
}
.login-box .signup-link p {
	margin: 0;
}

.input-field .form-control {
	font-size: 14px;
}

.form-group .invalid {
	color: #b94a48;
	font-size: 12px;
	margin-top: 5px;
}

.form-group .help-text {
	color: #555;
	font-style: italic;
	font-size: 12px;
	margin-top: 5px;
}

.form-group .domainame .form-control {
	padding-right: 130px;
}
.form-group .domainame .help-text {
	position: absolute;
    top: 5px;
    right: 0;
    font-weight: 600;
}

.back-link:before {
    width: 0;
    height: 1px;
    right: 0;
    background: #444;
    -webkit-transition: ease all .3s;
    -moz-transition: ease all .3s;
    -o-transition: ease all .3s;
    transition: ease all .3s;
}
.back-link:after, .back-link:before{
	position: absolute;
    bottom: 0;
    content: ""
}
.back-link:after {
    width: 100%;
    height: 40%;
    left: 0;
    transition: ease all .3s;
    background: #021B79;
    opacity: .3
}
.back-link:hover:before {
    width: 100%;
    transition-delay: .4s;
}
.back-link:hover:after {
    width: 0px;
    left: 0;
}
.back-link {
    -webkit-transition: ease all .3s;
    -moz-transition: ease all .3s;
    -o-transition: ease all .3s;
    transition: ease all .3s;
    color: #000!important;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    text-decoration: none !important;
    text-transform: uppercase;
}
/***************/

.portlet-box {
    position: relative;
}
.dcd-datepicker .react-datepicker-wrapper {
    position: absolute;
    top: 20px;
    right: 0;
    width: 200px;
    margin: auto;
    left: 0;
}

.dcd-datepicker .react-datepicker__input-container:before {
    content: "As of";
    margin-right: 0px;
    font-size: 16px;

}
.dcd-datepicker .react-datepicker__input-container:after {
    content: "\f073";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration:none;
}

.dcd-datepicker .react-datepicker-wrapper input {
    width: 90px;
    border: none;
    font-weight: 600;
    outline: none;
    text-align: center;
    padding-right: 3px;
}

/*==============================
  404Page
================================*/
.page-404 {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.page-404 .container {
    position: relative;
}
.page-404 .navbar-brand  {
    position: absolute;
    top: 0;
    left: 0;
}
.box-404 {
    text-align: center;
    padding: 10%;
    background: rgba(255,255,255,.7);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}
.box-404 h2 {
    font-size: 120px;
    font-weight: 800;
    letter-spacing: 10px;
    color: #000;
}
.box-404 p {
    margin: 0 0 30px;
    font-size: 25px;
}

.daily-collection-print{
  position: absolute;
  top: 10px;
  right: 0;
  width: 200px;
  margin: auto;
}
.paymentdtl-main{     margin: auto;
    max-width: 340px;
    width: 100%;
    background: #fff;
    padding-bottom: 20px;
    box-shadow: #dcdcdc 0 0 30px 10px;

}
.paymentdtl-main .portlet-box .portlet-heading .col-md-6{    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;}
.paymentdtl-main .portlet-box .portlet-heading h2{margin-bottom:0px; font-weight: normal}
.paymentdtl-main .form-control-plaintext{ width: auto; max-width: 85%; vertical-align:middle; display: inline-block;}
.paymentdtl-main .portlet-box .portlet-body{padding: 0px;}
.paymentdtl-main .form-group{ margin-bottom: 0px; padding: 5px 20px; border-bottom: #f1f1f1 1px solid;}
.paymentdtl-main .form-group:nth-child(even) {
    background: #fbfafa;
}
.btn-set.text-left .btn.pay-btn{ background-color:#1d4990;
    color: #fff;
    font-weight: 600;
    font-size: 13px;}
.btn-set.text-left .btn.pay-btn:hover{ background:#1d4990;}
.btn-set.text-left .btn.pay-btn:focus{box-shadow: none;}
.paymentdtl-main .portlet-box .portlet-heading{color: #fff;background: #528FF0;}
.react-datepicker-popper{z-index: 999 !important}
.paymentdtl-main .input-view label{ min-width: 60px; vertical-align: middle; margin-right: 10px; display: inline-block}
.portlet-box.company-users-prt .btn-show.btn-notification{ background:#fd962d !important;}
.portlet-box.company-users-prt .btn-edit.btn-envelope{ background:#ff3300 !important;}
.portlet-box.company-users-prt .btn-edit.btn-unread {background: #56a7cd !important;}
.react-bs-table .table-bordered .unread-tr td{border-bottom: 1px solid #f9f9f9; background: #ffe5cd !important;}
.react-bs-table .table-bordered .unread-tr td span{ font-weight: 700;}
@media only screen and (max-width: 767px)  {
    .mobilenav{ float: right; width: 26px; cursor: pointer; margin-top: 20px;}
    .mobilenav i{float:left; clear: both; margin-top: 3px; width: 100%; height: 3px; background: #000;}
    .navigation-block .navbar-menu{ position: absolute; display: none; width: 100%;
        top: 100%; left: 0; background: #fff; box-shadow: #000 0px 4px 10px -6px;}
    .navigation-block .navbar-menu li{width: 100%; clear: both; float: left; border-top:#e6e6e6 1px solid;}
    .navigation-block .navbar-menu li a{line-height: 40px;}
}
.react-datepicker__input-container, .react-datepicker-wrapper{ width: 100%; display: flow-root !important; }
.select2-container{ width: 100% !important; }

/* CUSTOM */
/* .day-month-btn{text-align: center;} */
