@media screen and (max-width:1169px) {
  .container-main{text-align: left;}
  .container-main h2{text-align: left;}
  .card-box-count label{ font-size:22px; }
  .card-box .card-box-info{ width: 100%; min-height: 50px;}
  .portlet-body .nav-link{padding:.5rem 0.6rem;}
  .company-users-prt .react-bs-table-search-form{ width: 50%; float: right;}
}
@media screen and (max-width:1023px) {
  .company-users-prt .react-bs-table-search-form{ width: 100%; float: left;}
  .card-box .card-box-info{ width: auto; }
  .navbar-menu > li > a{padding: 0 12px 0}
  .react-bs-table-container .react-bs-table-search-form{ margin-top: 20px; }
  .react-bs-table-bordered .table{ min-width: 830px;}
  .company-users-prt .react-bs-table-container .react-bs-table-search-form{ margin-top: 0px; }
  .company-users-prt .react-bs-table-bordered .table{min-width: 760px;}
  .react-bs-table-pagination{text-align: left;}
  .admincmnmid{text-align: left;}
  .admincmnmid .btn-group{display: inline-block;}
  .admincmnmid .btn{ padding:0 13px;}
  .input-field .select2-container{ width: 100% !important; }
  .portlet-body .nav-tabs{border-right:#ccc 1px solid }
  .portlet-body .nav-tabs .nav-item{ cursor: pointer; width: 25%; border-left: #ccc 1px solid; border-top: #ccc 1px solid; text-align: center; background: #f1f1f1;margin-bottom:0px;}
  .portlet-body .nav-tabs .nav-link.active{border:#fff;}
  .react-bs-table-container .btn-group-sm{ margin-top: 0px; }
  .react-bs-table-container .react-bs-table-bordered .table { min-width: 767px;}
  .collection-data .portlet-body .nav-tabs .nav-item{border: none;}
  .collection-data .portlet-body .nav-tabs{border-right: none;}
  .collection-data .portlet-body .nav-tabs .nav-link.active {
      border: #fff;
      padding-top: 8px;
      padding-bottom: 10px;
      position: relative;
      bottom: -1px;
  }
}
@media screen and (max-width:767px) {
  .company-users-prt .btn-group{ margin-bottom: 20px; }
  .portlet-body .nav-tabs .nav-item{width: 100%;}
  .collection-data .react-bs-table-container .react-bs-table-search-form{ margin-top: 50px; }
  .paymentdtl-main{margin:0 3%; max-width: 94%;}
  .paymentdtl-main .form-control-plaintext{max-width: 75%;}
}
@media screen and (max-width:599px) {
  .react-bootstrap-table-page-btns-ul{float:left;}
  .insportlet-box .btn{float: left; margin-top: 0px;}
  .admincmnmid .btn-group{display: block;}
  .admincmnmid .btn-group .btn{ margin-top: 0px; margin-bottom: 20px;}
  .admincmnmid .react-bootstrap-table-page-btns-ul{float: left; text-align: left; margin-top: 20px;margin-bottom: 0px;}
  .insportlet-box .portlet-box .btn{padding: 0 20px;}
  .portlet-box .btn{padding:0 12px;font-size: 12px; margin-bottom: 20px;}
  .container-main{text-align:center;}
  footer .powered-text{ text-align: center !important; padding-top:10px; }
  .daily-collection-print{ width: 97%; margin-top: 40px; }
  .collection-data .portlet-heading{ padding-bottom: 90px;}
  .collection-data .dcd-datepicker .react-datepicker-wrapper{ top: 55px }
  .input-field .react-datepicker__input-container, .input-field .react-datepicker-wrapper{ width: 100%; }
  h3.form-title{text-align: left;}
  .form-group{text-align: left;}
  .collection-data .react-bs-table-container .react-bs-table-search-form{ margin-top: 0px;}
}
@media screen and (max-width:374px) {
}
