.tr-string-example, .th-string-example{
    font-style: oblique;
    color: red;
    background-color: ligh;
}

.tr-function-example{
    font-weight: bold;
    background-color: aquamarine;
}

.th-header-string-example{
    font-style: oblique;
    font-weight: bold;
    color: black;
    background-color: lightgray;
}

.td-column-string-example{
    background-color: antiquewhite;
}

.td-column-function-odd-example{
    background-color: whitesmoke;
}

.td-column-function-even-example{
    background: white;
}

.my-header-class {
    background-color: #0066FF;
}

.my-body-class {
    background-color: #CC00FF;
}

.my-container-class {
    border: #0000FF 2.5px solid;
}

.my-table-container-class {
    border: #FFBB73 5px solid;
}

.class-for-editing-cell {
    background-color: #FFBB73;
}

.dropdown-menu > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
}